
<div>
  <v-btn
    v-for="suggestion in suggestions"
    :key="suggestion.id"
    class="mx-1"
    rounded
    outlined
    @click="store.newMessage(suggestion.prompt)"
  >
    {{ suggestion.title }}
  </v-btn>
</div>
