import moment from 'moment'
import { dateStr, timeFromStr } from '@/libs/dateTime'
import clone from 'lodash/clone'
import fromPairs from 'lodash/fromPairs'
import sortBy from 'lodash/sortBy'
import {
  filterByLocationVisibility,
  filterByLocationId,
} from '../../core/utils'

import {
  actionStateTypes,
  getters as types,
  columnTypeEntities,
  columnTypeForeignKeys,
  columnTypeGroups,
} from '@/types/calendar'

import { modes, CalendarMode } from '../../../types/calendar'

const cloneFound = (subject, needleId) =>
  clone(subject.find(({ id }) => id === needleId))

const GET_TIME_TO_Y_AXIS =
  ({
    options: {
      interval: { first, minutes, height },
    },
  }) =>
  (time) => {
    const parsedTime = timeFromStr(time)
    const minutesFromMidnight = parsedTime.diff(
      moment(parsedTime).startOf('day'),
      'minutes'
    )

    const firstTop = first * height
    const absoluteTop = (minutesFromMidnight / minutes) * height
    return absoluteTop - firstTop
  }

const businessHoursByWeekday = (businessHours, weekday) =>
  businessHours.filter(({ wday }) => wday === weekday)

const invertIntervals = (intervals, timeToY) => {
  const sortedIntervals = sortBy(intervals, 'startAt')
  const result = []
  const startOfDayTop = timeToY('00:00')
  const endOfDayTop = timeToY('23:59')
  let currentTop = startOfDayTop

  for (let i = 0, len = sortedIntervals.length; i < len; i++) {
    const top = timeToY(sortedIntervals[i].startAt)
    const bottom = timeToY(sortedIntervals[i].endAt)

    result.push({
      id: i,
      top: currentTop,
      height: top - currentTop,
    })

    currentTop = bottom
  }

  if (currentTop < endOfDayTop) {
    result.push({
      id: intervals.length,
      top: currentTop,
      height: endOfDayTop - currentTop,
    })
  }
  return result
}

const GET_INTERVAL_HEIGHT = ({
  options: {
    interval: { height },
  },
}) => {
  return height
}

const GET_RESOURCE_BLOCKERS =
  (_state, getters) =>
  ({ weekday }) => {
    const businessHours =
      getters[types.PRACTICE_HOURS_BY_WEEKDAY][weekday] || []

    // As we're creating blocker elements for the spaces between working hours,
    // we've got to invert the intervals
    const timeToY = getters[types.GET_TIME_TO_Y_AXIS]
    return invertIntervals(businessHours, timeToY)
  }

const GET_PHYSICIAN_BLOCKERS =
  (_state, getters) =>
  ({ weekday, physician }) => {
    const businessHours = businessHoursByWeekday(
      physician.businessHours,
      weekday
    )
    const timeToY = getters[types.GET_TIME_TO_Y_AXIS]

    // As we're creating blocker elements for the spaces between working hours,
    // we've got to invert the intervals
    return invertIntervals(businessHours || [], timeToY)
  }

/**
 * Selectec lanes from current columnType
 *
 * @returns {Array} selected column items
 */
const SELECTED_COLUMN_ITEMS = ({ columns }, getters) => {
  const columnItems = getters[types.COLUMN_ITEMS]
  const { selectedItems, group: selectedGroup } = columns[columns.type]
  if (selectedGroup === columnTypeGroups.default) {
    return columnItems
  }
  if (selectedGroup) {
    return columnItems.filter(({ group }) => group === selectedGroup)
  }
  return columnItems.filter(({ id }) => selectedItems.includes(id))
}

/**
 * Get the not selected ids of current column types items
 *
 * @param {Object} _state
 * @param {Object} getters
 * @param {number[]} selectedIds
 */
const COLUMN_ITEMS_OTHER_IDS =
  (_state, getters) =>
  (selectedIds = []) => {
    return getters[types.COLUMN_ITEMS]
      .filter(({ id }) => !selectedIds.includes(id))
      .map(({ id }) => id)
  }

/**
 * column items according to the states current columns.type
 */
const COLUMN_ITEMS = (state, getters, rootState, rootGetters) => {
  const items = state[columnTypeEntities[state.columns.type]]
  const locationId = rootGetters['common/getCurrentLocationId']

  if (state.columns.type === 'rooms') {
    return filterByLocationId(items, locationId)
  }

  return filterByLocationVisibility(items, locationId)
}

/**
 *
 * @typedef {Object} columnItemGroup
 * @property {string} id the column groups id
 * @property {Object[]} items
 * @property {string} name the i18next translation key
 * @returns {columnItemGroup[]}
 */
const GET_COLUMN_GROUPS = ({ columns: { type } }, getters) => {
  return columnTypeGroups[type].map((id) => ({
    id,
    items: getters[types.COLUMN_ITEMS].filter(({ group }) => group === id),
    name: `column_groups.${type}.${id}`,
  }))
}

export default {
  [types.PRACTICE_HOURS_BY_WEEKDAY](state) {
    return state.businessHours.reduce((map, current) => {
      if (!Array.isArray(map[current.wday])) {
        map[current.wday] = []
      }
      map[current.wday].push(current)
      return map
    }, {})
  },
  [types.GET_EVENTS_CALENDAR_RESOURCES]: (state) => (type) =>
    state.calendarResources.filter(
      ({ infrastructureType }) => infrastructureType === type
    ),

  [types.GET_EVENTS_ROOM]:
    (state) =>
    ({ calendarRoomId }) =>
      cloneFound(state.calendarRooms, calendarRoomId),

  [types.GET_EVENTS_PHYSICIAN]:
    (state) =>
    ({ physicianId }) =>
      cloneFound(state.physicians, physicianId),

  [types.GET_EVENTS_TYPE]:
    (state) =>
    ({ eventTypeId }) =>
      clone(state.eventTypes.find(({ id }) => id === eventTypeId) || {}),

  [types.GET_EVENT_TYPES]:
    ({ eventTypes }) =>
    () =>
      eventTypes,

  [types.GET_DAY_COLUMN_EVENTS]:
    (_, getters) =>
    ({ date, fullDay, ...params }) => {
      const foreignKey = getters[types.GET_COLUMN_RELATION_KEY]
      const eventsOfCurrentLocation = getters.getEventsOfCurrentLocation

      return eventsOfCurrentLocation.filter((event) => {
        return (
          event[foreignKey] === params[foreignKey] &&
          dateStr(event.start) === date &&
          event.allDay === fullDay
        )
      })
    },

  [types.GET_TIME_TO_Y_AXIS]: GET_TIME_TO_Y_AXIS,
  [types.GET_INTERVAL_HEIGHT]: GET_INTERVAL_HEIGHT,

  // activeness of the different action states
  // returns object like { focus: true, cut: false, pste: false }
  [types.GET_ACTION_ACTIVENES]: (state) => {
    return fromPairs(
      actionStateTypes.map((action) => [
        action,
        state.actionStates[action].active,
      ])
    )
  },

  [types.GET_PHYSICIAN_BLOCKERS]: GET_PHYSICIAN_BLOCKERS,
  [types.GET_RESOURCE_BLOCKERS]: GET_RESOURCE_BLOCKERS,

  [types.COLUMN_ITEMS]: COLUMN_ITEMS,
  [types.COLUMN_ITEMS_OTHER_IDS]: COLUMN_ITEMS_OTHER_IDS,
  [types.SELECTED_COLUMN_ITEMS]: SELECTED_COLUMN_ITEMS,
  [types.GET_COLUMN_GROUPS]: GET_COLUMN_GROUPS,
  [types.GET_COLUMN_SELECTED_GROUP]: (state) =>
    state.columns[state.columns.type].group,
  [types.GET_COLUMN_SELECTED_LANES]: ({ columns }) =>
    columns[columns.type].selectedItems,

  [types.GET_COLUMN_RELATION_KEY]: (state) =>
    columnTypeForeignKeys[state.columns.type],
  currentMode: ({ mode }) => new CalendarMode(mode),
  modeOptions: () =>
    window.FeatureFlags.feature_flags_enable_online_booking
      ? [modes.CALENDAR, modes.PLANNING, modes.HISTORY]
      : [modes.CALENDAR, modes.HISTORY],
  getEventsOfCurrentLocation: (state, getters, rootState, rootGetters) => {
    return filterByLocationId(
      getters.getEventsForCurrentMode,
      rootGetters['common/getCurrentLocationId']
    )
  },
  getEventsForCurrentMode: (state, getters) =>
    state.events[getters.currentMode.mode].single.concat(
      state.events[getters.currentMode.mode].recurring
    ),
  getCalendarRoomsOfCurrentLocation: (
    { calendarRooms },
    getters,
    rootState,
    rootGetters
  ) =>
    filterByLocationId(
      calendarRooms,
      rootGetters['common/getCurrentLocationId']
    ),
  getCalendarResourcesOfCurrentLocation: (
    { calendarResources },
    getters,
    rootState,
    rootGetters
  ) =>
    filterByLocationId(
      calendarResources,
      rootGetters['common/getCurrentLocationId']
    ),
  getLocationSpecificColumnSelectionKey: (_, getters, rootState, rootGetters) =>
    `columns.selection.${rootGetters['common/getCurrentLocationId']}`,
}
