
<d-form :form-data.sync="formData">
  <d-modal>
    <d-modal-title title="Voice Input Debug Options" />
    <d-modal-content>
      <v-row>
        <v-col cols="12" md="6">
          <d-input-select
            v-model="formData.voice_model"
            :items="voice_models"
            label="Voice Model"
            hide-details
          />
        </v-col>
        <v-col cols="12" md="6">
          <d-input-select
            v-model="formData.correction_model"
            :items="correction_models"
            label="Correction Model"
            hide-details
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <d-input-textarea
            v-model="formData.prompt"
            label="Prompt"
            hide-details
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="4">
          <d-input-select
            v-model="formData.selected_audio"
            :items="test_files"
            label="Test Files"
            hide-details
          />
          <v-btn icon @click="transcribeSelected">
            <v-icon>mdi-play</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="8">
          <audio
            v-if="voiceInputStore.audioSrc"
            class="audio-player"
            :src="voiceInputStore.audioSrc"
            controls
          ></audio>
        </v-col>
      </v-row>
    </d-modal-content>

    <d-modal-actions>
      <d-btn role="secondary" label="common:cancel" @click="emit('cancel')" />
      <d-btn label="common:save" @click="save" />
    </d-modal-actions>
  </d-modal>
</d-form>
