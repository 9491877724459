
<v-row :justify="justify">
  <v-col :cols="cols" class="d-flex">
    <v-card flat :color="color" style="width: 100%">
      <v-card-text
        class="chat"
        @click="(e) => e.metaKey && handleEditMessage()"
      >
        <v-hover v-slot="{ hover }">
          <div>
            <v-skeleton-loader
              v-if="!(message.content || message.toolCalls?.length > 0)"
              type="paragraph"
              width="50%"
            ></v-skeleton-loader>

            <div v-if="toolCallStatus" class="d-flex mb-2 align-center">
              <v-progress-circular
                indeterminate
                color="primary"
                width="2"
                size="11"
              ></v-progress-circular>
              <div class="ml-2 text--secondary">
                {{ toolCallStatus }}
              </div>
            </div>

            <d-markdown
              v-if="message.content && !store.isEditingMessage(message.id)"
              :markdown="message.content"
            />
            <div
              v-if="
                message.role == 'user' && store.isEditingMessage(message.id)
              "
            >
              <d-input-textarea
                ref="editTextareaRef"
                :value="store.getEditedMessage(message.id)?.content"
                @input="
                  (value) => store.updateEditedMessage(message.id, value)
                "
                @keydown.meta.enter="store.commitEditedMessage(message.id)"
                @keydown.esc="store.cancelEditingMessage(message.id)"
              />
              <d-btn @click="store.cancelEditingMessage(message.id)">
                {{ $t('common:cancel') }}
              </d-btn>
              <d-btn @click="store.commitEditedMessage(message.id)">
                {{ $t('common:save') }}
              </d-btn>
            </div>

            <fragment v-if="showToolCalls">
              <div v-for="(toolCall, idx) in message.toolCalls" :key="idx">
                <playground-tool-call :tool-call="toolCall" />
              </div>
            </fragment>

            <div v-if="message.attachments?.length > 0">
              <v-list>
                <playground-file
                  v-for="(file, idx) in message.attachments"
                  :key="idx"
                  :file="file"
                />
              </v-list>
            </div>
            <div v-if="hover" class="message-actions">
              <v-btn
                v-if="message.role === 'user'"
                class="my-1 mx-1"
                icon
                small
                @click="handleEditMessage"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                v-if="message.role === 'assistant'"
                class="my-1 mx-1"
                icon
                small
                @click="store.regenerateLastMessage()"
              >
                <v-icon small>mdi-refresh</v-icon>
              </v-btn>

              <v-btn
                class="my-1 mx-1"
                icon
                small
                @click="showToolCalls = !showToolCalls"
              >
                <v-icon small>mdi-code-json</v-icon>
              </v-btn>

              <v-btn
                class="my-1 mx-1"
                icon
                small
                @click="store.copy(message.content)"
              >
                <v-icon small>mdi-content-copy</v-icon>
              </v-btn>

              <v-btn
                icon
                small
                class="my-1 mx-1"
                @click="store.deleteMessage(message.id)"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
        </v-hover>
      </v-card-text>
    </v-card>
  </v-col>
</v-row>
