
<v-textarea
  v-model="model"
  :readonly="computedReadonly"
  v-bind="{ ...defaultInputBindings, ...$attrs }"
  v-on="{ ...$listeners, ...defaultHandlers }"
>
  <slot v-for="(_, name) in $slots" :slot="name" :name="name" />

  <template v-for="(_, slotName) in $scopedSlots" #[slotName]="slotData">
    <slot :name="slotName" v-bind="slotData" />
  </template>

  <template v-if="voiceEnabled" #append>
    <voice-input @transcribed="onTranscribed" />
  </template>
</v-textarea>
