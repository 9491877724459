import fromPairs from 'lodash/fromPairs'

export const modes = {
  PLANNING: 'planning',
  CALENDAR: 'calendar',
  HISTORY: 'history',
}

export class CalendarMode {
  constructor(mode) {
    this.mode = mode
  }

  get isPlanningMode() {
    return this.mode === modes.PLANNING
  }

  get isCalendarMode() {
    return this.mode === modes.CALENDAR
  }

  get isHistoryMode() {
    return this.mode === modes.HISTORY
  }

  toString() {
    return this.mode
  }
}

export const viewTypes = {
  day: 'day',
  week: 'week',
}

export const customViewTypeViewTypeMap = {
  day: 'day',
  full_week: 'week',
  work_week: 'week',
}

export const customViewTypes = {
  day: 'day',
  full_week: 'full_week',
  work_week: 'work_week',
}

export const fullWeekDays = [1, 2, 3, 4, 5, 6, 0]
export const workWeekDays = [1, 2, 3, 4, 5]

export const viewTypeWeekdays = {
  day: fullWeekDays,
  work_week: workWeekDays,
  full_week: fullWeekDays,
}

export const columnTypes = {
  people: 'people',
  rooms: 'rooms',
}

export const columnTypeGroups = {
  default: 'all',
  [columnTypes.people]: ['physician', 'dhpa', 'da'],
  [columnTypes.rooms]: ['room'],
}

export const columnTypeEntities = {
  [columnTypes.people]: 'physicians',
  [columnTypes.rooms]: 'calendarRooms',
}

export const columnTypeForeignKeys = {
  [columnTypes.people]: 'physicianId',
  [columnTypes.rooms]: 'calendarRoomId',
}

export const patientReminderTypes = ['email', 'sms']

export const actionStateTypes = ['focus', 'cut', 'copy', 'paste']

export const actionTypeSetMutations = fromPairs(
  actionStateTypes.map((actionTypeName) => [
    actionTypeName,
    `SET_CALENDAR_${actionTypeName.toUpperCase()}`,
  ])
)

export const actionStateMutationsComplete = {
  ...actionTypeSetMutations,
  snackbar: 'SET_CALENDAR_SNAKBAR',
}

export const actionStateActions = {
  flush: 'FLUSH_ACTION_STATES',
  focus: 'FOCUS_EVENT',
  cut: 'CUT_EVENT',
  copy: 'COPY_EVENT',
  paste: 'PASTE_EVENT',
}

// Main store Types
export const actions = {
  // action state actions
  ...fromPairs(
    Object.values(actionStateActions).map((value) => [value, value])
  ),

  CHANGE_RANGE_AND_VIEW_TYPE: 'CHANGE_RANGE_AND_VIEW_TYPE',
  CHANGE_COLUMN_TYPE: 'CHANGE_COLUMN_TYPE',
  CHANGE_COLUMN_SELECTION: 'CHANGE_COLUMN_SELECTION',
  SET_COLUMN_GROUP: 'SET_COLUMN_GROUP',
  SET_COLUMN_ITEM_SELECTION: 'SET_COLUMN_ITEM_SELECTION',
  TOGGLE_SNACKBAR: 'TOGGLE_SNACKBAR',
  TOGGLE_ACTION_ESC_LISTENER: 'TOGGLE_ACTION_ESC_LISTENER',
  RELOAD_EVENTS: 'RELOAD_EVENTS',
  RELOAD_SINGLE_EVENTS: 'RELOAD_SINGLE_EVENTS',
  RELOAD_RECURRING_EVENTS: 'RELOAD_RECURRING_EVENTS',
  FETCH_PATIENT_EVENT_REMINDER_BODIES: 'FETCH_PATIENT_EVENT_REMINDER_BODIES',
  SEND_PATIENT_EVENT_REMINDER: 'SEND_PATIENT_EVENT_REMINDER',
  SETUP_INITIAL_STATE: 'SETUP_INITIAL_STATE',
  SET_CALENDAR_INTERVAL_HEIGHT: 'SET_CALENDAR_INTERVAL_HEIGHT',
  SET_CALENDAR_INTERVAL_HEIGHT_SETTING: 'SET_CALENDAR_INTERVAL_HEIGHT_SETTING',
  FETCH_EVENT_TYPES: 'FETCH_EVENT_TYPES',
}

export const mutations = {
  SET_LOCALE_OPTION: 'SET_LOCALE_OPTION',
  SET_CALENDAR_NOW: 'SET_CALENDAR_NOW',
  SET_BUSINESS_HOURS: 'SET_BUSINESS_HOURS',
  SET_CALENDAR_INTERVAL_HEIGHT: 'SET_CALENDAR_INTERVAL_HEIGHT',
  SET_CALENDAR_INTERVAL_HEIGHT_SETTING: 'SET_CALENDAR_INTERVAL_HEIGHT_SETTING',
  SET_CALENDAR_INTERVAL_MINUTES: 'SET_CALENDAR_INTERVAL_MINUTES',
  SET_CALENDAR_INTERVAL_COUNT: 'SET_CALENDAR_INTERVAL_COUNT',
  SET_CALENDAR_FIRST_INTERVAL: 'SET_CALENDAR_FIRST_INTERVAL',
  MERGE_CALENDAR_OPTIONS: 'MERGE_CALENDAR_OPTIONS',

  SET_CALENDAR_RESOURCES: 'SET_CALENDAR_RESOURCES',
  SET_CALENDAR_ROOMS: 'SET_CALENDAR_ROOMS',
  SET_EVENT_TYPES: 'SET_EVENT_TYPES',
  SET_PHYSICIANS: 'SET_PHYSICIANS',
  SET_COLUMN_TYPE: 'SET_COLUMN_TYPE',
  SET_COLUMN_GROUP: 'SET_COLUMN_GROUP',
  SET_COLUMN_TYPE_SELECTION: 'SET_COLUMN_TYPE_SELECTION',
  SET_CALENDAR_FIRST_HOUR: 'SET_CALENDAR_FIRST_HOUR',
  SET_CALENDAR_LAST_HOUR: 'SET_CALENDAR_LAST_HOUR',

  SET_EVENTS: 'SET_EVENTS',

  SET_EVENT_REMINDER_EMAIL_BODY: 'SET_EVENT_REMINDER_EMAIL_BODY',
  SET_EVENT_REMINDER_SMS_BODY: 'SET_EVENT_REMINDER_SMS_BODY',
  SET_EVENT_REMINDER_SUBJECT: 'SET_EVENT_REMINDER_SUBJECT',
  FLUSH_EVENT_REMINDER_BODIES: 'FLUSH_EVENT_REMINDER_BODIES',

  SET_CALENDAR_SNAKBAR: 'SET_CALENDAR_SNAKBAR',
  // define mutation names from previously generated.
  // example { focus: 'SET_CALENDAR_FOCUS } => { SET_CALENDAR_FOCUS: 'SET_CALENDAR_FOCUS' }
  ...fromPairs(
    Object.values(actionStateMutationsComplete).map((value) => [value, value])
  ),
}

export const getters = {
  GET_EVENTS_ROOM: 'GET_EVENTS_ROOM',
  GET_EVENTS_PHYSICIAN: 'GET_EVENTS_PHYSICIAN',
  GET_EVENTS_TYPE: 'GET_EVENTS_TYPE',
  GET_EVENT_TYPES: 'GET_EVENT_TYPES',
  GET_DAY_COLUMN_EVENTS: 'GET_DAY_COLUMN_EVENTS',
  GET_PHYSICIAN_BLOCKERS: 'GET_PHYSICIAN_BLOCKERS',
  GET_RESOURCE_BLOCKERS: 'GET_RESOURCE_BLOCKERS',
  GET_COLUMN_RELATION_KEY: 'GET_COLUMN_RELATION_KEY',
  PRACTICE_HOURS_BY_WEEKDAY: 'PRACTICE_HOURS_BY_WEEKDAY',

  COLUMN_ITEMS: 'COLUMN_ITEMS',
  COLUMN_ITEMS_OTHER_IDS: 'COLUMN_ITEMS_OTHER_IDS',
  SELECTED_COLUMN_ITEMS: 'SELECTED_COLUMN_ITEMS',
  GET_COLUMN_GROUPS: 'GET_COLUMN_GROUPS',
  GET_COLUMN_SELECTED_GROUP: 'GET_COLUMN_SELECTED_GROUP',
  GET_COLUMN_SELECTED_LANES: 'GET_COLUMN_SELECTED_LANES',

  GET_TIME_TO_Y_AXIS: 'GET_TIME_TO_Y_AXIS',
  GET_INTERVAL_HEIGHT: 'GET_INTERVAL_HEIGHT',

  // action state getters
  GET_ACTION_ACTIVENES: 'GET_ACTION_ACTIVENES',
}

export default {
  // Main store Types
  actions,
  getters,
  mutations,
  // subsets
  actionStateActions,
  actionStateMutationsComplete,
  actionStateTypes,
  actionTypeSetMutations,
  columnTypeEntities,
  columnTypeForeignKeys,
  columnTypeGroups,
  columnTypes,
  customViewTypes,
  customViewTypeViewTypeMap,
  patientReminderTypes,
  fullWeekDays,
  viewTypes,
  viewTypeWeekdays,
  workWeekDays,
}
