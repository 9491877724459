import { defineStore } from 'pinia'
import type Dialog from '@/types/dialog'
import VoiceInputDebugDialog from './components/voice-input-debug-dialog.vue'
import gql from 'graphql-tag'
import { apolloClient } from '@/plugins/apollo-client'
import { provideApolloClient } from '@vue/apollo-composable'
import { useTranslation } from '@/i18n/i18n'

declare global {
  interface Window {
    DenteoInitialStoreState?: {
      common?: {
        user?: {
          permission?: string
        }
      }
    }
  }
}

type AudioChunks = Array<Blob | string | ArrayBuffer>

interface VoiceInputState {
  voice_model: string
  correction_model: string
  prompt: string
  recording: boolean
  transcribing: boolean
  audioSrc: string
  activeRecorder: MediaRecorder | null
  dialog: Dialog | null
  keyHandlerInstalled: boolean
  hasError: boolean
}

interface RecorderConfig {
  mimeType: string
  audioBitsPerSecond: number
}

type VoiceInputError = {
  type: 'transcription' | 'recording' | 'media'
  message: string
  originalError?: unknown
}

const VOICE_TRANSCRIBE_MUTATION = gql`
  mutation VoiceTranscribe(
    $file: String!
    $voiceModel: String
    $correctionModel: String
    $prompt: String
  ) {
    voiceTranscribe(
      file: $file
      voiceModel: $voiceModel
      correctionModel: $correctionModel
      prompt: $prompt
    ) {
      transcription {
        text
      }
      errors {
        message
      }
    }
  }
`

const { t } = useTranslation()

// Convert Blob to Base64
function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}

export const useVoiceInputStore = defineStore('voice_input', {
  state: (): VoiceInputState => ({
    voice_model: 'Whisper',
    correction_model: '',
    prompt: '',
    recording: false,
    transcribing: false,
    audioSrc: '',
    activeRecorder: null,
    dialog: null,
    keyHandlerInstalled: false,
    hasError: false,
  }),

  actions: {
    setModelParameters(params: {
      voice_model: string
      correction_model: string
      prompt: string
    }) {
      this.voice_model = params.voice_model
      this.correction_model = params.correction_model
      this.prompt = params.prompt
    },

    setError(hasError: boolean) {
      this.hasError = hasError
      if (hasError) {
        // Auto-clear error state after snackbar duration (6s)
        setTimeout(() => {
          this.hasError = false
        }, 6000)
      }
    },

    async transcribeAudio(audioBlob: Blob): Promise<string> {
      const base64Data = await blobToBase64(audioBlob)

      try {
        this.setError(false)
        const { data } = await provideApolloClient(apolloClient)(() =>
          apolloClient.mutate({
            mutation: VOICE_TRANSCRIBE_MUTATION,
            variables: {
              file: base64Data,
              voiceModel: this.voice_model,
              correctionModel: this.correction_model,
              prompt: this.prompt,
            },
          })
        )

        if (data.voiceTranscribe.errors) {
          this.setError(true)
          throw {
            type: 'transcription',
            message: data.voiceTranscribe.errors[0].message,
          } as VoiceInputError
        }

        return data.voiceTranscribe.transcription.text
      } catch (error) {
        this.setError(true)
        console.error('Transcription failed:', error)
        throw {
          type: 'transcription',
          message: t('voice_input:errors.transcription_failed'),
          originalError: error,
        } as VoiceInputError
      }
    },

    getRecorderConfig(): RecorderConfig {
      // Detect browser support for different formats
      const mimeTypes = ['audio/webm', 'audio/mp4']

      let mimeType
      if (typeof MediaRecorder.isTypeSupported === 'function') {
        mimeType = mimeTypes.find((type) => MediaRecorder.isTypeSupported(type))
      } else {
        // Assuming iOS Safari on iPhone
        // See https://caniuse.com/mdn-api_mediasource_istypesupported_static
        mimeType = 'audio/mp4'
      }

      return {
        mimeType,
        audioBitsPerSecond: 128000,
      }
    },

    async setupMediaRecorder(
      config: RecorderConfig,
      chunks: AudioChunks
    ): Promise<{ recorder: MediaRecorder; stream: MediaStream }> {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        })
        const recorder = new MediaRecorder(stream, config)

        recorder.addEventListener('dataavailable', (event) => {
          if (typeof event.data === 'undefined' || event.data.size === 0) return
          chunks.push(event.data)
        })

        return { recorder, stream }
      } catch (error) {
        throw {
          type: 'media',
          message: t('voice_input:errors.media_recorder_setup_failed'),
          originalError: error,
        } as VoiceInputError
      }
    },

    async handleRecordingStop(
      chunks: AudioChunks,
      stream: MediaStream,
      resolve: (value: string | undefined) => void,
      reject: (reason: VoiceInputError) => void
    ): Promise<void> {
      this.activeRecorder = null
      const recording = new Blob(chunks, {
        type: this.getRecorderConfig().mimeType,
      })
      this.audioSrc = URL.createObjectURL(recording)
      this.recording = false
      this.transcribing = true

      try {
        const correction = await this.transcribeAudio(recording)
        resolve(correction)
      } catch (err) {
        console.error('Failed to transcribe:', err)
        reject(err as VoiceInputError)
      } finally {
        this.transcribing = false
        stream.getTracks().forEach((track) => track.stop())
      }
    },

    async startRecording(): Promise<string | undefined> {
      const chunks: AudioChunks = []
      const config = this.getRecorderConfig()

      try {
        const { recorder, stream } = await this.setupMediaRecorder(
          config,
          chunks
        )
        this.activeRecorder = recorder

        return new Promise((resolve, reject) => {
          recorder.addEventListener('stop', () => {
            this.handleRecordingStop(chunks, stream, resolve, reject)
          })

          recorder.onerror = (event) => {
            this.activeRecorder = null
            this.recording = false
            reject({
              type: 'recording',
              message: t('voice_input:errors.recording_error'),
              originalError: event,
            } as VoiceInputError)
          }

          recorder.start()
          this.recording = true
        })
      } catch (err) {
        console.error('Failed to start recording:', err)
        this.activeRecorder = null
        this.recording = false
        throw err as VoiceInputError
      }
    },

    stopRecording(): void {
      if (this.activeRecorder && this.activeRecorder.state !== 'inactive') {
        this.activeRecorder.stop()
      }
      this.recording = false
    },

    showDebugDialog(): Promise<string | undefined> {
      if (!this.dialog)
        return Promise.reject(t('voice_input:errors.dialog_not_initialized'))

      return this.dialog.show({
        component: VoiceInputDebugDialog,
        width: 800,
      })
    },

    initializeDialog(dialog: Dialog) {
      this.dialog = dialog
    },

    async handleDebugShortcut(event: KeyboardEvent) {
      if (event.ctrlKey && event.key === 'd') {
        event.preventDefault()
        return this.showDebugDialog()
      }
    },

    setupKeyHandler() {
      if (this.keyHandlerInstalled) return
      if (
        window.DenteoInitialStoreState?.common?.user?.permission !==
        'superadmin'
      )
        return
      window.addEventListener('keydown', this.handleDebugShortcut.bind(this))
      this.keyHandlerInstalled = true
    },

    teardownKeyHandler() {
      if (!this.keyHandlerInstalled) return
      window.removeEventListener('keydown', this.handleDebugShortcut.bind(this))
      this.keyHandlerInstalled = false
    },
  },
})
