import PlaygroundChat from './playground-chat.vue'

export const playgroundChatRoutes = {
  name: 'chat',
  path: '/chats/:conversationId?',
  component: PlaygroundChat,
  props: (route) => ({
    conversationId: route.params.conversationId,
    assistantName: route.query.assistantName,
  }),
}
