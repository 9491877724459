import { feeCatalogBrowserRoutes } from './fee-catalog-browser/routes'
import { playgroundChatRoutes } from './playground/routes'

const rootRoute = {
  path: '/',
  redirect: () => {
    // On the /playground page, the root route is the chat
    if (window.location.pathname === '/playground') {
      return {
        name: 'chat',
        params: { conversationId: 'new' },
      }
    }

    // On the /fee-catalogs page, the root route is the fee catalog browser
    if (window.location.pathname === '/fee-catalogs') {
      return { name: 'feeCatalogBrowser' }
    }

    // This should only happen if the user is on a page
    // which has a router-view, but no routing is defined for it
    return { name: 'notFound' }
  },
}

const notFoundRoute = {
  path: '/not-found',
  name: 'notFound',
  component: () => '<div>Not Found</div>',
}

export {
  rootRoute,
  notFoundRoute,
  playgroundChatRoutes,
  feeCatalogBrowserRoutes,
}
