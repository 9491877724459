<template>
  <v-btn icon :color="color" @click="toggleRecording">
    <v-icon>{{ icon }}</v-icon>
  </v-btn>
</template>

<script lang="ts">
import { onMounted, onBeforeUnmount, inject, computed } from '@vue/composition-api';
import { useVoiceInputStore } from '../voice-input-store';
import { useSnackbar } from '@/libs/use-snackbar';
import type Dialog from '@/types/dialog';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const dialog: Dialog = inject('$dialog');
  const voiceInputStore = useVoiceInputStore();
  const {
    showSnackbar
  } = useSnackbar();

  // Initialize dialog service in store and setup handlers
  voiceInputStore.initializeDialog(dialog);

  // Handle transcription results from debug dialog
  voiceInputStore.$onAction(({
    name,
    after
  }) => {
    if (name === 'showDebugDialog') {
      after(result => {
        if (result) {
          emit('transcribed', (result as string));
        }
      });
    }
  });
  const color = computed(() => {
    if (voiceInputStore.hasError) return 'error';
    if (voiceInputStore.recording) return 'red';
    if (voiceInputStore.transcribing) return 'green';
    return '';
  });
  const icon = computed(() => {
    if (voiceInputStore.hasError) return 'mdi-alert-circle';
    if (voiceInputStore.recording) return 'mdi-radiobox-marked d-mdi-pulse';
    if (voiceInputStore.transcribing) return 'mdi-loading mdi-spin';
    return 'mdi-microphone';
  });
  async function toggleRecording() {
    if (voiceInputStore.recording) {
      voiceInputStore.stopRecording();
    } else {
      try {
        const transcription = await voiceInputStore.startRecording();
        if (transcription) {
          emit('transcribed', transcription);
        }
      } catch (err) {
        console.error('Recording failed:', err);
        if (err && typeof err === 'object' && 'message' in err) {
          showSnackbar({
            text: (err.message as string)
          });
        }
      }
    }
  }

  // Setup debug shortcut handler
  onMounted(() => {
    voiceInputStore.setupKeyHandler();
  });
  onBeforeUnmount(() => {
    voiceInputStore.teardownKeyHandler();
  });
  return {
    color,
    icon,
    toggleRecording
  };
};
export default __sfc_main;
</script>

<style scoped>
/* Pulse animation, TODO: global availability */
/* eslint-disable vue-scoped-css/no-unused-selector */
.d-mdi-pulse {
  animation: pulse 1.5s ease-in-out infinite;
}
/* eslint-enable vue-scoped-css/no-unused-selector */

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>
