
<div>
  <ul v-if="Object.keys(asset.arguments).length">
    <li v-for="(value, key) in asset.arguments" :key="key">
      <strong>{{ key }}:</strong>
      {{ value }}
    </li>
  </ul>

  <v-divider class="my-2"></v-divider>

  <d-markdown v-if="asset.content" :markdown="asset.content" />
</div>
