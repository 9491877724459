import {
  mutations,
  getters as getterTypes,
  actions,
  customViewTypes,
  customViewTypeViewTypeMap,
  viewTypeWeekdays,
  columnTypeGroups,
  modes,
} from '@/types/calendar'
import { dateFromStr, dateStr } from '@/libs/dateTime'
import api from '@/libs/api'
import actionStateActions from './actionStateActions'
import moment from 'moment'
import localSetting from '../../../services/local-setting'
import eventTypeService from '../../../services/event-type'
import eventService from '../../../services/event'

/**
 * Switch the week, days start date and/or the viewType
 * @name CHANGE_RANGE_AND_VIEW_TYPE
 *
 * @param {Object} vuexAction
 * @param {Object} params
 * @param {String} params.customViewType the customViewType - default or existing taken if none given
 * @param {String} params.start the range start date string `YYYY-MM-DD`
 * @param {Boolean} params.initial if true given it doesn't reload events (initially we get the events as props)
 *
 * ```js
 * this.$store.dispatch(
 *   'CHANGE_RANGE_AND_VIEW_TYPE',
 *    { customViewType: 'work_week', start: '2019-12-02', initial: true }
 * )
 * ```
 */
const CHANGE_RANGE_AND_VIEW_TYPE = async (
  { state, commit, dispatch },
  {
    customViewType = null,
    start = null,
    initial = false,
    forceStartDate = false,
  }
) => {
  // Handle special rules to move to current day if switching to day view in current week
  if (customViewType === customViewTypes.day) {
    const today = moment()
    const { range } = state.options
    const todayIsInCurrentWeek = today.isBetween(
      range.start,
      range.end,
      'day',
      '[]'
    )
    if (todayIsInCurrentWeek && !forceStartDate) {
      start = today
    }
  }
  const startMoment = getRangeStartMoment(state, start)
  const evaluatedViewType = getCustomViewTypeStartDependent(
    state,
    startMoment,
    customViewType
  )

  await commit(mutations.MERGE_CALENDAR_OPTIONS, {
    customViewType: evaluatedViewType,
    viewType: customViewTypeViewTypeMap[evaluatedViewType],
    weekdays: viewTypeWeekdays[evaluatedViewType],
    range: {
      start: getRangeStartDateString(evaluatedViewType, startMoment),
      end: getRangeEndDateString(evaluatedViewType, startMoment),
    },
  })
  if (!initial) {
    await dispatch(actions.RELOAD_EVENTS)
  }
  localSetting.setCustomViewType(evaluatedViewType)
}

/**
 * get moment instance for calendar start (required by CHANGE_RANGE_AND_VIEW_TYPE)
 *
 * @param {Object} state the vuex state
 * @param {String} start the calendar start `YYYY-MM-DD` to to convert to moment instance
 */
const getRangeStartMoment = (state, start) => {
  const startMoment = dateFromStr(
    start || state.options.range.start || moment().startOf('isoWeek')
  )
  if (!startMoment.isValid()) {
    throw new Error(`Invalid calendar start date ${startMoment}`)
  }
  return startMoment
}

/**
 * customViewType altered if startMoment requires it to be (required by CHANGE_RANGE_AND_VIEW_TYPE)
 *
 * @param {Object} state  the vuex state
 * @param {moment} startMoment moment instance of the range start
 * @param {String} customViewType the customViewType we intend to set
 */
const getCustomViewTypeStartDependent = (
  state,
  startMoment,
  customViewType = null
) => {
  const isMobile = window.matchMedia('(max-width: 600px)').matches
  // for mobile, we only allow day view
  const setViewType = isMobile
    ? customViewTypes.day
    : customViewType ||
      localSetting.getCustomViewType() || // from localStore if none given
      state.options.customViewType || // default from options
      customViewTypes.work_week // types default if really all previous not present
  if (
    startMoment.isoWeekday() > 5 &&
    setViewType === customViewTypes.work_week
  ) {
    return customViewTypes.full_week
  }
  return setViewType
}

/**
 * Get correct range start datestring `YYYY-MM-DD` (required by CHANGE_RANGE_AND_VIEW_TYPE)
 *
 * @param {String} costumViewType the custom view type [day|work_week|full_week]
 * @param {moment} startMoment the range start moment given
 */
const getRangeStartDateString = (costumViewType, startMoment) =>
  costumViewType !== customViewTypes.day
    ? dateStr(startMoment.startOf('isoWeek'))
    : dateStr(startMoment)

/**
 * Get correct range end datestring `YYYY-MM-DD` (required by CHANGE_RANGE_AND_VIEW_TYPE)
 *
 * @param {String} customViewType  the custom view type [day|work_week|full_week]
 * @param {moment} startMoment the range start moment given
 */
const getRangeEndDateString = (customViewType, startMoment) => {
  // work week needs end on friday
  if (customViewType === customViewTypes.work_week) {
    return dateStr(startMoment.day(5))
  }
  // for day view start and end are the same
  if (customViewType === customViewTypes.day) {
    return dateStr(startMoment)
  }
  // full week needs end on sunday and its the safest fallback
  return dateStr(startMoment.endOf('isoWeek'))
}

/**
 * Change the calendars column selection, by selectedItems, or by group
 *
 * Requires either selectedItems with ids, or group
 *
 * @param {any} vuex
 * @param {Object} params
 * @param {number[]} [params.selectedItems=[]] array with selected lane ids
 * @param {string} [params.group=null] the selected column group
 * @param {boolean} [params.initial=false] load saved selection or default when initializing
 * @param {boolean} [params.typeSwitch=false] load saved selection when switchin type
 */
const CHANGE_COLUMN_SELECTION = async (
  {
    dispatch,
    getters,
    state: {
      columns: { type },
    },
  },
  { selectedItems = [], group = null, initial = false, typeSwitch = false }
) => {
  if (initial || typeSwitch) {
    return handleInitialOrTypeSwitch({
      dispatch,
      getters,
      type,
      typeSwitch,
    })
  }
  if (group || !selectedItems.length) {
    return dispatch(actions.SET_COLUMN_GROUP, group)
  }
  dispatch(actions.SET_COLUMN_ITEM_SELECTION, selectedItems)
}

/**
 * Handler for initializing column selection from localstore
 *
 * @param {Object} params
 * @param {function} params.dispatch vuex dispatch
 * @param {Object} params.getters vuex getters
 * @param {string} params.type
 * @param {boolean} params.typeSwitch
 */
const handleInitialOrTypeSwitch = ({ dispatch, getters, type, typeSwitch }) => {
  const { notSelected, group } = localSetting.getColumnSelection(
    getters.getLocationSpecificColumnSelectionKey
  )[type]
  if (
    !typeSwitch &&
    (!notSelected ||
      (!notSelected.length && group === columnTypeGroups.default))
  ) {
    return dispatch(actions.SET_COLUMN_GROUP, columnTypeGroups.default)
  }
  // load the saved group
  if (group) {
    return dispatch(actions.SET_COLUMN_GROUP, group)
  }
  // load the saved item selection
  const selectedLanes = getters.COLUMN_ITEMS_OTHER_IDS(notSelected)
  dispatch(actions.SET_COLUMN_ITEM_SELECTION, selectedLanes)
}

/**
 * Set the column group to store and save in browsers local store
 *
 * @param {any} vuex
 * @param {string} [group='people'] the column item group
 */
const SET_COLUMN_GROUP = (
  {
    commit,
    getters,
    state: {
      columns: { type },
    },
  },
  group = columnTypeGroups.default
) => {
  commit(mutations.SET_COLUMN_TYPE_SELECTION, {
    selectedItems: [],
    group,
  })
  localSetting.setColumnSelection(
    getters.getLocationSpecificColumnSelectionKey,
    {
      type,
      [type]: { notSelected: [], group },
    }
  )
}

/**
 * Set selected column items to store and save to browsers local store
 *
 * @param {any} vuex
 * @param {number[]} [selectedItems=[]] the selected lane items
 */
const SET_COLUMN_ITEM_SELECTION = (
  {
    commit,
    dispatch,
    getters,
    state: {
      columns: { type },
    },
  },
  selectedItems = []
) => {
  const notSelected = getters[getterTypes.COLUMN_ITEMS_OTHER_IDS](selectedItems)
  if (notSelected.length === getters[getterTypes.COLUMN_ITEMS].length) {
    return dispatch(actions.SET_COLUMN_GROUP)
  }
  commit(mutations.SET_COLUMN_TYPE_SELECTION, { selectedItems, group: null })
  localSetting.setColumnSelection(
    getters.getLocationSpecificColumnSelectionKey,
    {
      type,
      [type]: { notSelected, group: null },
    }
  )
  dispatch(actions.RELOAD_EVENTS)
}

export default {
  ...actionStateActions,

  [actions.RELOAD_RECURRING_EVENTS]: async ({ commit, getters }, params) => {
    params.recurring = true
    const currentMode = getters.currentMode

    const { events, errors } = await api.events.index({ params })
    if (errors || !events) {
      return console.log({ errors, events })
    }
    return commit(mutations.SET_EVENTS, {
      events,
      mode: currentMode.mode,
      kind: 'recurring',
    })
  },

  [actions.RELOAD_SINGLE_EVENTS]: async ({ commit, getters }, params) => {
    const currentMode = getters.currentMode
    const { events, errors } = await api.events.index({ params })
    if (errors || !events) {
      return console.log({ errors, events })
    }
    return commit(mutations.SET_EVENTS, {
      events,
      mode: currentMode.mode,
      kind: 'single',
    })
  },

  [actions.RELOAD_EVENTS]: async ({ dispatch, getters, state }) => {
    const locationId = getters['common/getCurrentLocationId']
    const range = {
      from: state.options.range.start,
      to: state.options.range.end,
    }
    const params = {}
    if (locationId) {
      params.locationId = locationId
    }
    if (getters.currentMode.isPlanningMode) {
      params.eventType = 'booking_available'
    } else if (getters.currentMode.isHistoryMode) {
      params.eventType = 'historic'
    }
    await dispatch(actions.RELOAD_SINGLE_EVENTS, { ...range, ...params })
    // historic events are all returned in the previous call
    if (
      getters.currentMode.isCalendarMode ||
      getters.currentMode.isPlanningMode
    ) {
      await dispatch(actions.RELOAD_RECURRING_EVENTS, {
        ...range,
        ...params,
      })
    }
  },

  [actions.CHANGE_RANGE_AND_VIEW_TYPE]: CHANGE_RANGE_AND_VIEW_TYPE,

  [actions.CHANGE_COLUMN_TYPE]: async (
    { dispatch, commit, getters },
    columnType
  ) => {
    localSetting.setColumnSelection(
      getters.getLocationSpecificColumnSelectionKey,
      { type: columnType }
    )
    commit(mutations.SET_COLUMN_TYPE, columnType)
    dispatch(actions.CHANGE_COLUMN_SELECTION, { typeSwitch: true })
  },
  [actions.CHANGE_COLUMN_SELECTION]: CHANGE_COLUMN_SELECTION,
  [actions.SET_COLUMN_GROUP]: SET_COLUMN_GROUP,
  [actions.SET_COLUMN_ITEM_SELECTION]: SET_COLUMN_ITEM_SELECTION,

  [actions.FETCH_PATIENT_EVENT_REMINDER_BODIES]: async ({ commit }, { id }) => {
    const { sms, email, subject } = await api.notification_templates.show(id)
    commit(mutations.SET_EVENT_REMINDER_EMAIL_BODY, email)
    commit(mutations.SET_EVENT_REMINDER_SUBJECT, subject)
    commit(mutations.SET_EVENT_REMINDER_SMS_BODY, sms)
  },

  [actions.SEND_PATIENT_EVENT_REMINDER]: async (
    { state, commit },
    { id, currentReminderType, saveAddress = false, recipientAddress = null }
  ) => {
    const body = state.reminderContents[currentReminderType]
    const subject = state.reminderContents.subject
    const response = await api.events.send_appointment(id, {
      [currentReminderType]: recipientAddress,
      body,
      subject: currentReminderType === 'email' && subject,
      saveAddress,
      reminderType: currentReminderType,
    })
    if (!response || !response.success) {
      return false
    }
    await commit(mutations.FLUSH_EVENT_REMINDER_BODIES)
    return true
  },

  [actions.SETUP_INITIAL_STATE]: async (
    { commit, dispatch, getters },
    {
      calendarResources,
      calendarRooms,
      eventTypes,
      teamMembers,
      events,
      businessHours,
      focusedEvent = null,
      settings,
    }
  ) => {
    const storedCalendarInterval = localStorage.getItem(
      'calendar_interval_height'
    )
    // load settings from local storage or default

    // this can be 0
    const calendarIntervalHeight =
      storedCalendarInterval != null
        ? +storedCalendarInterval
        : settings.calendarIntervalHeight
    const calendarDefaultViewType = settings.calendarDefaultViewType
    const calendarIntervalMinute =
      +localStorage.getItem('calendar_interval_minute') ||
      settings.calendarIntervalMinute
    const calendarScrollToHour =
      localStorage.getItem('calendar_scroll_to_hour') ||
      settings.calendarScrollToHour
    commit(mutations.SET_CALENDAR_FIRST_HOUR, calendarScrollToHour)
    const calendarLastShownHour =
      localStorage.getItem('calendar_last_shown_hour') ||
      settings.calendarLastShownHour
    commit(mutations.SET_CALENDAR_LAST_HOUR, calendarLastShownHour)

    const savedColumnSelection = localSetting.getColumnSelection(
      getters.getLocationSpecificColumnSelectionKey
    )
    commit(mutations.SET_COLUMN_TYPE, savedColumnSelection.type)
    commit(mutations.SET_CALENDAR_INTERVAL_HEIGHT, calendarIntervalHeight)
    // this is needed because SET_CALENDAR_INTERVAL_HEIGHT is modified multiple times (and we need to keep the setting value)
    commit(
      mutations.SET_CALENDAR_INTERVAL_HEIGHT_SETTING,
      calendarIntervalHeight
    )
    commit(mutations.SET_CALENDAR_INTERVAL_MINUTES, calendarIntervalMinute)
    const startAtMinutes = moment(calendarScrollToHour, 'HH:mm').minutes()
    const endAtMinutes = moment(calendarLastShownHour, 'HH:mm').minutes()
    // 8:05 -> 8:00
    const startBuffer = startAtMinutes === 0 ? 0 : -startAtMinutes
    // 19:40 -> 20:00
    const endBuffer = endAtMinutes === 0 ? 0 : 60 - endAtMinutes

    const workingMinutesPerDay =
      moment(calendarLastShownHour, 'HH:mm').diff(
        moment(calendarScrollToHour, 'HH:mm'),
        'minutes'
      ) +
      Math.abs(startBuffer) +
      Math.abs(endBuffer)

    // https://vuetifyjs.com/en/api/v-calendar/#props-first-interval
    const firstInterval =
      (60 / calendarIntervalMinute) *
      moment(calendarScrollToHour, 'HH:mm').hours()
    commit(mutations.SET_CALENDAR_FIRST_INTERVAL, firstInterval)

    commit(
      mutations.SET_CALENDAR_INTERVAL_COUNT,
      workingMinutesPerDay / calendarIntervalMinute
    )

    commit(mutations.SET_BUSINESS_HOURS, businessHours)
    commit(mutations.SET_EVENTS, {
      events,
      mode: getters.currentMode.mode,
      kind: 'single',
    })
    commit(mutations.SET_CALENDAR_RESOURCES, calendarResources)
    commit(mutations.SET_CALENDAR_ROOMS, calendarRooms)
    commit(mutations.SET_EVENT_TYPES, eventTypes)
    commit(mutations.SET_PHYSICIANS, teamMembers)
    commit(mutations.SET_LOCALE_OPTION, window.DenteoGlobals.locale)

    commit('SET_MODE', modes.CALENDAR)

    const customViewType = localSetting.getCustomViewType(
      calendarDefaultViewType
    )
    let startingAt = moment()

    if (
      customViewType === customViewTypes.full_week ||
      customViewType === customViewTypes.work_week
    ) {
      startingAt = moment().startOf('isoWeek')
    }

    if (focusedEvent) {
      dispatch(actions.FOCUS_EVENT, { event: focusedEvent })
      startingAt = moment(focusedEvent.start)
    }

    await dispatch(actions.CHANGE_RANGE_AND_VIEW_TYPE, {
      start: startingAt,
      customViewType,
      initial: true,
      forceStartDate: true,
    })

    dispatch(actions.CHANGE_COLUMN_SELECTION, {
      initial: true,
      savedSelection: savedColumnSelection,
    })
  },

  [actions.SET_CALENDAR_FIRST_HOUR]: ({ commit }, firstHour) => {
    commit(mutations.SET_CALENDAR_FIRST_HOUR, firstHour)
  },

  [actions.SET_CALENDAR_LAST_HOUR]: ({ commit }, lastHour) => {
    commit(mutations.SET_CALENDAR_LAST_HOUR, lastHour)
  },

  [actions.SET_CALENDAR_INTERVAL_HEIGHT]: (
    { commit },
    calendarIntervalHeight
  ) => commit(mutations.SET_CALENDAR_INTERVAL_HEIGHT, calendarIntervalHeight),
  [actions.FETCH_EVENT_TYPES]: async ({ commit }) => {
    const { eventTypes } = await eventTypeService.fetchAll()
    commit(mutations.SET_EVENT_TYPES, eventTypes)
  },
  changeMode: ({ commit }, mode) => {
    commit('SET_MODE', mode)
  },
  gotoCurrentVersionOfEvent: async ({ commit, dispatch }, eventId) => {
    // load latest version of the given eventId
    // we don't pass in the event directly because it's a historic version
    const { event } = await eventService.fetch(eventId)

    dispatch('common/setCurrentLocationId', event.locationId)

    const customViewType = localSetting.getCustomViewType(
      customViewTypes.work_week
    )

    commit('SET_MODE', modes.CALENDAR)

    dispatch(actions.FOCUS_EVENT, { event })

    await dispatch(actions.CHANGE_RANGE_AND_VIEW_TYPE, {
      start: moment(event.start),
      customViewType,
      initial: false,
      forceStartDate: true,
    })
  },
}
