
<transition name="trs-fade-out" mode="out-in">
  <fragment>
    <div v-if="enableLoading && loading" class="table-loader">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
      <span class="table-loader__text">{{ $t('loading') }}</span>
    </div>
    <v-simple-table
      v-else
      v-bind="{ ...defaultAttrs, ...$attrs }"
      class="nested-table mb-4"
      :class="{
        'nested-table--selectable': selectable,
        'nested-table--sortable': sortable,
      }"
    >
      <template #default>
        <thead class="nested-table__head">
          <tr v-if="!hideHeader">
            <th v-if="sortable" class="nested-table__col-min" />
            <th
              v-if="selectable || (sortable && hasAnyNested)"
              class="nested-table__col-min"
            >
              <v-simple-checkbox
                v-if="selectable"
                color="primary"
                :indeterminate="someItemsSelected"
                :value="allItemsSelected"
                @input="mainCheckboxUpdate"
              />
            </th>
            <th
              v-for="(header, index) in headers"
              :key="index"
              :class="header.class"
              :style="{
                width: header.width,
                'max-width': header.maxWidth,
              }"
            >
              <d-tooltip bottom>
                <template #activator="{ on }">
                  <div v-on="on">
                    <slot
                      :name="headerSlotPrefix + header.value"
                      :header="header"
                    >
                      <span>{{ $t(header.text) }}</span>
                    </slot>
                  </div>
                </template>
                {{ $t(header.tooltip) || $t(header.text) }}
              </d-tooltip>
            </th>
            <th v-if="hasActions" class="nested-table__actions" />
          </tr>
          <tr
            v-if="hasBannerSlot && showBanner"
            class="nested-table__banner-tr"
          >
            <td class="nested-table__banner-td" colspan="100">
              <div class="nested-table__banner">
                <slot name="banner" />
              </div>
            </td>
          </tr>
        </thead>
        <tbody
          v-if="!sectionsAndItems.length && ($slots.empty || noDataText)"
          class="nested-table__body"
        >
          <tr>
            <td class="text-center no-data-text" colspan="100">
              <slot v-if="!noDataText" name="empty"></slot>
              <span v-else>{{ noDataText }}</span>
            </td>
          </tr>
        </tbody>
        <draggable
          v-model="sectionsAndItems"
          class="nested-table__draggable-wrapper"
          ghost-class="nested-table__ghost"
          drag-class="nested-table__drag"
          chosen-class="nested-table__chosen"
          selected-class="nested-table__selected"
          draggable=".nested-table__body"
          filter="thead"
          handle=".nested-table__drag-handle"
          :animation="sortAnimationDuration"
          :multi-drag="true"
          :disabled="!itemDraggable"
          @end="reselectSelectedItem"
        >
          <tbody
            v-for="(item, itemIndex) in sectionsAndItems"
            :key="item.id ? item.id : itemIndex"
            class="nested-table__body"
            :class="{
              'nested-table__body--with-children': hasNested(item),
            }"
          >
            <tr
              :id="item[itemId]"
              ref="items"
              :class="{
                'nested-table__click-event-handle': hasClickRowEventListener,
                highlight: isItemHighlighted(item),
                'fade-slowly': isItemFadingSlowly(item),
              }"
              @click="itemClick(item)"
              @dblclick.prevent="if (!item.isSection) itemDoubleClick(item)"
            >
              <td
                v-if="sortable"
                class="nested-table__col-min nested-table__drag-handle"
              >
                <v-icon small> mdi-drag-horizontal-variant </v-icon>
              </td>
              <td
                v-if="selectable || (sortable && hasAnyNested)"
                class="nested-table__col-min"
              >
                <v-simple-checkbox
                  v-if="item.isSection && sectionSelectable(item)"
                  color="primary"
                  :value="isSectionSelected(item)"
                  :indeterminate="isSectionIndeterminate(item)"
                  @input="sectionSelect(item, $event)"
                />
                <v-simple-checkbox
                  v-else-if="itemSelectable(item)"
                  color="primary"
                  :value="isSelected(item)"
                  @input="itemSelect(item, $event)"
                />
                <v-simple-checkbox
                  v-else-if="
                    !itemSelectable(item) && !tooltipForNonSelectable(item)
                  "
                  color="primary"
                  :disabled="true"
                  off-icon="mdi-alert-box-outline"
                  :value="isSelected(item)"
                  @input="itemSelect(item, $event)"
                />
                <d-tooltip
                  v-else-if="
                    !itemSelectable(item) && tooltipForNonSelectable(item)
                  "
                  bottom
                >
                  <template #activator="{ on }">
                    <v-simple-checkbox
                      color="primary"
                      :disabled="true"
                      off-icon="mdi-alert-box-outline"
                      :value="isSelected(item)"
                      v-on="on"
                      @input="itemSelect(item, $event)"
                    />
                  </template>
                  <span>{{ tooltipForNonSelectable(item) }}</span>
                </d-tooltip>
              </td>
              <template v-for="(header, headerIndex) in headers">
                <td
                  v-if="!item.isSection || header.value === 'name'"
                  :key="headerIndex"
                  :colspan="item.isSection ? headers.length : 1"
                  :class="header.class"
                  :style="{
                    width: header.width,
                    'max-width': header.maxWidth,
                    'min-width': header.minWidth,
                  }"
                >
                  <slot :name="itemSlotPrefix + header.value" :item="item">
                    {{ item[header.value] ? item[header.value] : '–' }}
                  </slot>
                </td>
              </template>
              <td v-if="hasActions" class="nested-table__actions">
                <slot name="actions" :item="item" />
              </td>
            </tr>
            <template v-if="hasNested(item)">
              <draggable
                v-model="item[nestedKey]"
                tag="tr"
                ghost-class="nested-table__ghost"
                drag-class="nested-table__drag"
                chosen-class="nested-table__chosen"
                class="nested-table__draggable-wrapper"
                handle=".nested-table__child-drag-handle"
                :animation="sortAnimationDuration"
                :disabled="!itemDraggable"
                @change="updateChildSort(item)"
                @end="reselectSelectedItem"
              >
                <tr
                  v-for="(child, childIndex) in item[nestedKey]"
                  :id="child[itemId]"
                  :key="childIndex"
                  ref="nestedItems"
                  class="nested-table__row nested-table__row--nested"
                  :class="{
                    highlight: isItemHighlighted(item),
                    'fade-slowly': isItemFadingSlowly(item),
                  }"
                >
                  <td v-if="sortable" class="nested-table__col-min" />
                  <td
                    v-if="selectable || sortable"
                    class="nested-table__col-min nested-table__child-drag-handle"
                  >
                    <v-icon v-if="sortable" small>
                      mdi-drag-horizontal-variant
                    </v-icon>
                  </td>
                  <td
                    v-for="(header, columnIndex) in headers"
                    :key="header.key"
                    :class="header.class"
                    :style="{
                      width: header.width,
                      'max-width': header.maxWidth,
                    }"
                  >
                    <span v-if="columnIndex === 0" class="d-flex">
                      <v-icon small>mdi-subdirectory-arrow-right</v-icon>
                      <slot
                        :name="childItemSlotPrefix + header.value"
                        :item="child"
                      >
                        {{ child[header.value] }}
                      </slot>
                    </span>
                    <slot
                      v-else
                      :name="childItemSlotPrefix + header.value"
                      :item="child"
                    >
                      {{ child[header.value] ? child[header.value] : '–' }}
                    </slot>
                  </td>
                  <td v-if="hasActions" class="nested-table__actions">
                    <slot name="child.actions" :item="child" />
                  </td>
                </tr>
                <tr class="nested-table__row__spacer">
                  <td colspan="999"></td>
                </tr>
              </draggable>
            </template>
          </tbody>
        </draggable>
        <tfoot
          v-if="hasFooterSlot"
          class="nested-table__footer"
          :class="{
            'nested-table__footer--highlighted': !hideFooterBackground,
            'nested-table__footer--with-border': showFooterBorder,
          }"
        >
          <tr>
            <td v-if="sortable" />
            <td v-if="selectable || sortable" />
            <td
              v-for="(header, index) in headers"
              :key="index"
              :class="header.class"
            >
              <slot :name="footerSlotPrefix + header.value" />
            </td>
            <td v-if="hasActions" class="nested-table__actions" />
          </tr>
          <slot
            name="footer"
            :colspan-before="[selectable, sortable].filter((x) => x).length"
          >
          </slot>
        </tfoot>
      </template>
    </v-simple-table>
  </fragment>
</transition>
