import RestEntity from './rest-entity'

class Event extends RestEntity {
  entityName = 'event'

  transformEntityBeforeSave(event) {
    return {
      id: event.id || null,
      start: event.start.toString(),
      end: event.end.toString(),
      text: event.text,
      patientId: event.patientId || null,
      physicianId: event.physicianId,
      eventTypeId: event.eventTypeId,
      calendarRoomId: event.calendarRoomId || null, // vuetify_bug_1
      calendarResourceIds: event.calendarResourceIds,
      allDay: event.allDay,
      recurrence: event.recurrence,
      originalStart: event.originalStart || null,
      notify: event.notify || null,
      locationId: event.locationId,
    }
  }

  save(event, config) {
    return super.save(this.transformEntityBeforeSave(event), config)
  }

  async updateTeamMemberId(oldTeamMemberId, newTeamMemberId) {
    await this.post(`${oldTeamMemberId}/update_team_member_id`, {
      oldTeamMemberId,
      newTeamMemberId,
    })
  }

  async updateEventTypeOfTeamMember(teamMemberId, newEventTypeId) {
    await this.post(`${teamMemberId}/update_event_type_of_team_member`, {
      teamMemberId,
      newEventTypeId,
    })
  }

  async updateClientMissed(eventId, patientMissed) {
    await this.put(`${eventId}`, {
      patientMissed,
    })
  }
}

export default new Event()
