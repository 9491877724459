import forEach from 'lodash/forEach'
import cloneDeep from 'lodash/cloneDeep'
import pick from 'lodash/pick'
import moment from 'moment'
import { dateStr } from '@/libs/dateTime'

import {
  actions,
  actionTypeSetMutations,
  actionStateActions,
  mutations,
  columnTypes,
} from '@/types/calendar'

const canclers = {}
const eventPickAttributes = [
  'id',
  'physicianId',
  'eventTypeId',
  'patientId',
  'title',
  'text',
  'calendarRoomId',
  'calendarResourceIds',
  'patient',
  'isRecurring',
]

const generateFormEventTimes = (
  startingTime,
  durationMinutes = 30,
  originalStart = null
) => {
  const start = moment(startingTime)
  const end = moment(start).add(durationMinutes, 'minutes')
  return {
    start: start.toISOString(true),
    end: end.toISOString(true),
    date: dateStr(start),
    endDate: dateStr(end),
    duration: durationMinutes,
    originalStart: originalStart,
  }
}

export default {
  [actions.FLUSH_ACTION_STATES]: ({ commit, dispatch, getters }) => {
    dispatch(actions.TOGGLE_SNACKBAR, { flush: true })
    const actionActivness = getters.GET_ACTION_ACTIVENES
    forEach(actionTypeSetMutations, (setMutation, action) => {
      if (actionActivness[action]) {
        commit(setMutation)
      }
    })
  },

  [actionStateActions.focus]: (
    { commit, dispatch },
    { event = null, flush = false } = {}
  ) => {
    if (flush) {
      dispatch(actions.TOGGLE_ACTION_ESC_LISTENER)
      return commit(actionTypeSetMutations.focus) // flush focus
    }
    dispatch(actions.TOGGLE_ACTION_ESC_LISTENER, 'focus')
    commit(actionTypeSetMutations.focus, { event, active: true })
  },

  [actionStateActions.cut]: (
    { commit, state, dispatch },
    { flush = false, event = null } = {}
  ) => {
    if (flush) {
      dispatch(actions.TOGGLE_SNACKBAR, { flush: true })
      dispatch(actions.TOGGLE_ACTION_ESC_LISTENER)
      return commit(actionTypeSetMutations.cut) // flush cut
    }
    const cutEvent = cloneDeep(event || state.actionStates.focus.event) || null
    dispatch(actionStateActions.focus, { flush: true })
    commit(mutations.SET_CALENDAR_SNAKBAR, { active: true, action: 'cut' })
    commit(actionTypeSetMutations.copy) // flush copy
    dispatch(actions.TOGGLE_ACTION_ESC_LISTENER, 'cut')
    commit(actionTypeSetMutations.cut, {
      event: cutEvent,
      active: Boolean(cutEvent),
    })
  },

  [actionStateActions.copy]: (
    { commit, state, dispatch },
    { flush = false, event = null } = {}
  ) => {
    if (flush) {
      dispatch(actions.TOGGLE_SNACKBAR, { flush: true })
      dispatch(actions.TOGGLE_ACTION_ESC_LISTENER)
      return commit(actionTypeSetMutations.copy) // flush copy
    }
    const copyEvent = cloneDeep(event || state.actionStates.focus.event) || null

    delete copyEvent.id
    delete copyEvent.patientMissed
    delete copyEvent.latestVersion
    delete copyEvent.key

    dispatch(actionStateActions.focus, { flush: true })
    commit(mutations.SET_CALENDAR_SNAKBAR, { active: true, action: 'copy' })
    commit(actionTypeSetMutations.cut) // flush cut
    dispatch(actions.TOGGLE_ACTION_ESC_LISTENER, 'copy')
    commit(actionTypeSetMutations.copy, {
      event: copyEvent,
      active: Boolean(copyEvent),
    })
  },

  [actionStateActions.paste]: (
    { commit, state },
    {
      flush = false,
      pasteOn: {
        physicianId = null,
        calendarRoomId = null,
        allDay = false,
        start,
      } = {},
    } = {}
  ) => {
    if (
      flush ||
      (!state.actionStates.cut.active && !state.actionStates.copy.active)
    ) {
      return commit(actionTypeSetMutations.paste) // flush paste
    }

    let event = state.actionStates.cut.event

    if (state.actionStates.copy.active) {
      event = state.actionStates.copy.event
    }

    const columEntities =
      state.columns.type === columnTypes.rooms
        ? { calendarRoomId, physicianId: event.physicianId }
        : { physicianId }
    const pasteEvent = {
      ...pick(event, eventPickAttributes),
      ...columEntities,
      allDay,
      ...generateFormEventTimes(start, event.duration, event.start),
    }
    commit(actionTypeSetMutations.cut) // flush cut
    commit(actionTypeSetMutations.copy) // flush copy
    commit(actionTypeSetMutations.focus) // flush focus
    commit(mutations.SET_CALENDAR_SNAKBAR) // flush snackbar
    commit(actionTypeSetMutations.paste, {
      event: pasteEvent,
      active: true,
      originalEvent: event,
    })
  },

  [actions.TOGGLE_SNACKBAR]: (
    { commit, state },
    { flush = false, action = null }
  ) => {
    if (flush) {
      if (state.actionStates.snackbar.action) {
        commit(actionTypeSetMutations[state.actionStates.snackbar.action]) // flush snackbars action
      }
      return commit(mutations.SET_CALENDAR_SNAKBAR) // flush snackbar
    }
    commit(mutations.SET_CALENDAR_SNAKBAR, { active: true, action })
  },

  [actions.TOGGLE_ACTION_ESC_LISTENER]: ({ dispatch }, action = null) => {
    const body = document.querySelector('body')
    if (action) {
      const cancler = (domEvent) => {
        if (domEvent.keyCode === 27) {
          dispatch('FLUSH_ACTION_STATES')
        }
      }
      canclers.flushAll = cancler
      body.addEventListener('keyup', cancler)
    } else {
      body.removeEventListener('keyup', canclers.flushAll)
    }
  },
}
