<template>
  <v-textarea
    v-model="model"
    :readonly="computedReadonly"
    v-bind="{ ...defaultInputBindings, ...$attrs }"
    v-on="{ ...$listeners, ...defaultHandlers }"
  >
    <slot v-for="(_, name) in $slots" :slot="name" :name="name" />

    <template v-for="(_, slotName) in $scopedSlots" #[slotName]="slotData">
      <slot :name="slotName" v-bind="slotData" />
    </template>

    <template v-if="voiceEnabled" #append>
      <voice-input @transcribed="onTranscribed" />
    </template>
  </v-textarea>
</template>
<script>
import dInputComponentMixin from './d-input-component-mixin'
import VoiceInput from '@/modules/voice-input/components/voice-input.vue'
import { useVoiceInputStore } from '@/modules/voice-input/voice-input-store'

export default {
  components: {
    VoiceInput,
  },
  mixins: [dInputComponentMixin],
  props: {
    voice: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    voiceEnabled() {
      return this.$featureFlag.feature_flags_enable_voice_input && this.voice
    },
    isVoiceProcessing() {
      const voiceStore = useVoiceInputStore()
      return voiceStore.recording || voiceStore.transcribing
    },
    computedReadonly() {
      return this.$props.readonly || this.isVoiceProcessing
    },
  },
  methods: {
    onTranscribed(value) {
      const textarea = this.$el.querySelector('textarea')
      if (!textarea) return

      const start = textarea.selectionStart
      const end = textarea.selectionEnd
      const text = this.model || ''

      // Handle spacing around the inserted text
      let insertText = value
      if (text) {
        // Add leading space if not at start and previous char isn't a space
        if (start > 0 && text[start - 1] !== ' ') {
          insertText = ' ' + insertText
        }
        // Add trailing space if not at end and next char isn't a space
        if (end < text.length && text[end] !== ' ') {
          insertText = insertText + ' '
        }
      }

      // Construct the new text by combining the parts
      this.model = text.slice(0, start) + insertText + text.slice(end)

      // Set cursor position after the inserted text
      this.$nextTick(() => {
        const newPosition = start + insertText.length
        textarea.setSelectionRange(newPosition, newPosition)
        textarea.focus()
      })
    },
  },
}
</script>

<style scoped>
::v-deep .v-input__append-inner {
  position: sticky !important;
  top: 4px;
  margin-top: 4px !important;
}
</style>
