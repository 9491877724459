
<div>
  <d-data-table-loader
    :enable-loading="enableLoading"
    :loading="loading"
    :loading-message="loadingMessage"
  />
  <v-data-table
    v-show="!loading"
    :headers="tableHeaders"
    :items="tableItems"
    :search="search"
    :item-class="rowWithEvent"
    :items-per-page="itemsPerPage"
    :footer-props="mergedFooterProps"
    v-bind="{ ...defaultAttrs, ...$attrs }"
    :class="[tableClass, 'd-data-table', { 'fixed-width': fixedWidth }]"
    :hide-default-footer="pageInfo ? true : 'hide-default-footer' in $attrs"
    v-on="$listeners"
    @click:row="handleRowClick"
  >
    <template #header.select>
      <v-simple-checkbox
        v-if="selectable"
        color="primary"
        :indeterminate="someItemsSelected"
        :value="allItemsSelected"
        @input="toggleAllSelectableItems"
      />
    </template>
    <!-- Handle table with <template #item="{ item }"> slot (events-table.vue, findings-table.vue) -->
    <template v-if="$scopedSlots['item']" #item="itemProps">
      <slot name="item" v-bind="itemProps">-</slot>
    </template>

    <template v-if="$scopedSlots['no-data']" #no-data>
      <slot name="no-data">{{ $t('common:empty_table') }}</slot>
    </template>

    <template v-if="!$scopedSlots['item']" #body>
      <tbody v-if="tableItems.length === 0">
        <tr class="v-data-table__empty-wrapper">
          <td :colspan="tableHeaders.length" style="text-align: center">
            <slot name="no-data">{{
              search
                ? $t('common:no_search_result')
                : $t('common:empty_table')
            }}</slot>
          </td>
        </tr>
      </tbody>
      <draggable
        v-model="tableItems"
        tag="tbody"
        :class="{ 'draggable-wrapper': areItemsDraggable }"
        ghost-class="ghost-row"
        chosen-class="is-row-dragged"
        :disabled="!areItemsDraggable || isFilterApplied"
        :clone="onClone"
        :animation="sortAnimationDuration"
        @start="dragging = true"
        @end="onEnd"
      >
        <d-data-table-row
          v-for="(item, index) in tableItems"
          :key="index"
          :item="item"
          :headers="tableHeaders"
          :row-click="rowClickHandler ? handleRowClick : null"
          :dragging="dragging"
        >
          <template #item.select>
            <v-simple-checkbox
              v-if="itemSelectable(item)"
              color="primary"
              :value="isSelected(item)"
              @input="itemSelect(item, $event)"
            />
            <v-simple-checkbox
              v-else-if="
                !itemSelectable(item) && !tooltipForNonSelectable(item)
              "
              color="primary"
              :disabled="true"
              off-icon="mdi-alert-box-outline"
              :value="isSelected(item)"
              @input="itemSelect(item, $event)"
            />
            <d-tooltip
              v-else-if="
                !itemSelectable(item) && tooltipForNonSelectable(item)
              "
              bottom
            >
              <template #activator="{ on }">
                <v-simple-checkbox
                  color="primary"
                  :disabled="true"
                  off-icon="mdi-alert-box-outline"
                  :value="isSelected(item)"
                  v-on="on"
                  @input="itemSelect(item, $event)"
                />
              </template>
              <span>{{ tooltipForNonSelectable(item) }}</span>
            </d-tooltip>
          </template>
          <template #item.drag>
            <v-icon :color="isFilterApplied ? '#ccc' : ''" small>
              mdi-drag-horizontal-variant
            </v-icon>
          </template>
          <template v-for="(_, name) in $scopedSlots" #[name]="itemProps">
            <template v-if="name === 'item.actions'">
              <d-data-table-item-actions :key="name">
                <slot :name="name" v-bind="itemProps" />
              </d-data-table-item-actions>
            </template>
            <template v-else-if="name === 'item.visible-actions'">
              <div :key="name" class="d-flex">
                <slot :name="name" v-bind="itemProps" />
              </div>
            </template>
            <slot v-else :name="name" v-bind="itemProps">–</slot>
          </template>
        </d-data-table-row>
      </draggable>
    </template>

    <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
      {{ pageStart }}–{{ pageStop }} {{ $t('common:pagination_from') }}
      {{ itemsLength }}
    </template>
    <template #footer="{ props }">
      <div
        v-if="pageInfo"
        class="footer--border-top d-flex align-center justify-end px-6 py-2 print-hidden"
      >
        <span class="text-caption text-no-wrap">{{
          props.itemsPerPageText
        }}</span>
        <v-menu v-if="itemsPerPageOptions" offset-y>
          <template #activator="{ on }">
            <v-btn depressed class="ml-3 mr-7" small v-on="on" @click.stop>
              {{ itemsPerPage }}
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-card list>
            <v-list-item
              v-for="option in itemsPerPageOptions"
              :key="option.text || option"
              @click="
                $emit('paginate', {
                  action: 'refresh',
                  pageInfo,
                  itemsPerPage: option.text ? option.value : option,
                })
              "
            >
              <v-list-item-title>
                {{ option.text ? $t(option.text) : option }}
              </v-list-item-title>
              <slot></slot>
            </v-list-item>
          </v-card>
        </v-menu>
        <span class="text-caption mr-7 text-no-wrap"
          >Total: {{ itemsTotal }}</span
        >
        <v-btn
          type="button"
          icon
          :disabled="!pageInfo?.hasPreviousPage"
          @click="
            $emit('paginate', {
              action: 'start',
              pageInfo,
              itemsPerPage,
            })
          "
        >
          <v-icon>mdi-page-first</v-icon>
        </v-btn>
        <v-btn
          type="button"
          class="mr-2"
          icon
          :disabled="!pageInfo?.hasPreviousPage"
          @click="
            $emit('paginate', {
              action: 'prev',
              pageInfo,
              itemsPerPage,
            })
          "
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          type="button"
          class="ml-2"
          icon
          :disabled="!pageInfo?.hasNextPage"
          @click="
            $emit('paginate', {
              action: 'next',
              pageInfo,
              itemsPerPage,
            })
          "
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn
          type="button"
          icon
          :disabled="!pageInfo?.hasNextPage"
          @click="
            $emit('paginate', {
              action: 'end',
              pageInfo,
              itemsPerPage,
            })
          "
        >
          <v-icon>mdi-page-last</v-icon>
        </v-btn>
      </div>
    </template>
  </v-data-table>
</div>
