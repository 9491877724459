import { destroy, get, post, put } from '@/libs/axiosWrapper'
import snakeCase from 'lodash/snakeCase'
export default class RestEntity {
  apiBaseUrl = '/json'
  entityName = '' // Should be overridden by extending class.
  jsonPath = false
  singleResource = false // eg. /json/profile/update

  constructor(entityOwnerPath = null, entityName = '') {
    this.entityOwnerPath = entityOwnerPath
    this.entityName = entityName
  }

  get collectionName() {
    if (this.singleResource) {
      return this.entityName
    }

    return this.entityName + 's'
  }

  get railsResourceRoute() {
    return snakeCase(this.collectionName)
  }

  fetch(entityId, params = {}) {
    return get(this.entityPath(entityId), { params })
  }

  fetchAll(params = {}) {
    return get(this.entityPath(), { params })
  }

  save(entity, config) {
    const body = { [snakeCase(this.entityName)]: entity }
    if (entity.id && !this.singleResource) {
      return put(this.entityPath(entity.id), body, config)
    } else if (this.singleResource) {
      return put(this.entityPath(), body, config)
    }
    return post(this.entityPath(), body, config)
  }

  destroy(entityId, config) {
    return destroy(this.entityPath(entityId), config)
  }

  entityPath(additionalPath = []) {
    additionalPath = Array.isArray(additionalPath)
      ? additionalPath
      : [additionalPath]

    const path = [
      this.apiBaseUrl,
      this.entityOwnerPath,
      this.railsResourceRoute,
      ...additionalPath,
    ]
      .filter((item) => item)
      .join('/')

    return this.jsonPath ? `${path}.json` : path
  }

  get(path, config) {
    return get(this.entityPath(path), config)
  }

  post(path, data, config) {
    return post(this.entityPath(path), data, config)
  }

  put(path, data, config) {
    return put(this.entityPath(path), data, config)
  }
}
