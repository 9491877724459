import RestEntity from './rest-entity'

class EventType extends RestEntity {
  entityName = 'eventType'

  async getBookingType() {
    const { eventType } = await this.get('booking_available', {})
    return eventType
  }
}

export default new EventType()
