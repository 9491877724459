import RestEntity from './rest-entity'

class ClinicalRecord extends RestEntity {
  entityName = 'clinicalRecord'

  async findOrInitialize(obj = {}) {
    if (obj.id) {
      const { clinicalRecord } = await this.fetch(obj.id)
      return clinicalRecord
    } else {
      return obj
    }
  }

  defaultRecord(defaultValues = {}) {
    return {
      templateCategory: '0_general',
      templateName: '0_freitext',
      at: new Date().toISOString(),
      teeth: '',
      patientId: null,
      createdByPersonId: null,
      formValues: { freetext: '' },
      ...defaultValues,
    }
  }

  defaultRecordParoStatus(defaultValues = {}) {
    return {
      templateCategory: '1_klinische_befunde',
      templateName: '2_parodontale_befundaufnahme',
      at: new Date(),
      // at: new Date().toISOString(),
      isEditable: true,
      formValues: { teeth: [] },
      ...defaultValues,
    }
  }

  defaultRecordHygiene(defaultValues = {}) {
    return {
      templateCategory: '1_klinische_befunde',
      templateName: '4_mundhygiene',
      at: new Date(),
      isEditable: true,
      ...defaultValues,
    }
  }

  defaultRecordDenture(appName, defaultValues = {}) {
    let templateName
    switch (appName) {
      case 'mobility':
        templateName = '7_mobilitaet'
        break
      case 'vitality':
        templateName = '8_vitalitaet'
        break
    }
    return {
      templateCategory: '1_klinische_befunde',
      templateName,
      at: new Date(),
      isEditable: true,
      ...defaultValues,
    }
  }

  defaultRecordFillingAssistant(defaultValues = {}) {
    return {
      templateCategory: '1_klinische_befunde',
      templateName: 'filling_assistant', // TODO: should we use integer prefix?
      at: new Date(),
      patientId: null,
      teeth: null,
      physicianId: null,
      createdByPersonId: null,
      formValues: null,
      ...defaultValues,
    }
  }
}

export default new ClinicalRecord()
