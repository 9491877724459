import RestEntity from './rest-entity'
import pick from 'lodash/pick'
import snakeCase from 'lodash/snakeCase'

export const emptySection = Object.freeze({
  id: '-1', // null is already in use for sections that should newly be created
  name: '',
})

export const businessObjectTypes = {
  NTARIFF: 'Ntariff',
  TARIFF: 'Tariff',
  LAB: 'Lab',
  SAMPLE: 'Sample',
  ARTICLE: 'Article',
  DISCOUNT: 'Discount',
}

export const guarantorKinds = {
  PRIVATE: 'private',
  KVG: 'kvg',
  UVG: 'uvg',
  IVG: 'ivg',
  MVG: 'mvg',
  SCHOOL: 'school',
  MISC: 'misc',
  SOCIAL: 'social',
}

export const tariffTypes = {
  DENTOTAR: 'Ntariff',
  TARIFF94: 'Tariff',
}

export class Position extends RestEntity {
  businessObjectTypes = businessObjectTypes
  guarantorKinds = guarantorKinds
  tariffTypes = tariffTypes

  fetchPositions() {
    return this.get('').then((result) => result[this.collectionName])
  }

  savePosition(position) {
    const payload = this.payloadFromPosition(position)
    if (position.id) {
      return this.put([position.id], payload).then(
        (result) => result[this.entityName]
      )
    }
    return this.post('', payload).then((result) => result[this.entityName])
  }

  bulkUpdatePositions(positionIds, position) {
    return this.post(['bulk_update'], {
      [snakeCase(this.entityName)]: position,
      ids: positionIds,
    })
  }

  bulkDestroyPositions(positionIds) {
    return this.destroy(['bulk_destroy'], {
      data: {
        ids: positionIds,
      },
    })
  }

  evaluatePosition(position = { id: null }) {
    const payload = this.payloadFromPosition(position)
    return this.post(['evaluate'], payload).then(
      (result) => result[this.entityName]
    )
  }

  payloadFromPosition(position) {
    return {
      [this.entityName]: {
        ...position,
        subPositions:
          position.subPositions &&
          position.subPositions.map((pos) => ({
            ...pos,
            physicianId: position.physicianId,
            locationId: position.locationId,
          })),
      },
    }
  }

  sortPositions(ids) {
    return this.post('/sort', {
      ids,
    })
  }

  tariffTypeHasSubPositions(type) {
    return type === this.tariffTypes.DENTOTAR
  }

  emptyPosition(position) {
    return {
      businessObjectPolyId: null,
      physicianId: null,
      at: new Date().toISOString(),
      comment: null,
      tooth: '',
      quantity: 1,
      rate: null,
      unitPrice: null,
      percentageDiscount: null,
      guarantorKind: this.guarantorKinds.KVG,
      businessObjectType: this.tariffTypes.DENTOTAR,
      subPositions: [],
      totalPrice: null,
      ...position,
    }
  }

  emptySubPosition(locationId = null) {
    return {
      businessObjectPolyId: null,
      comment: '',
      quantity: 1,
      unitPrice: null,
      rate: null,
      totalPrice: null,
      locationId,
    }
  }

  undiscountableGuarantorKinds() {
    return [guarantorKinds.IVG, guarantorKinds.MVG, guarantorKinds.UVG]
  }
}
