
<v-container>
  <v-row v-if="store.currentAssistant?.taskSuggestions?.length > 0">
    <v-col cols="12">
      <playground-task-suggestions
        :suggestions="store.currentAssistant.taskSuggestions"
      />
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <d-input-textarea
        ref="inputField"
        v-model="store.messageInput"
        class="chat-area"
        placeholder="Type something..."
        rows="1"
        auto-grow
        autofocus
        rounded
        hide-details
        :disabled="store.loading"
        @keydown.enter.exact.prevent="chat"
      >
        <template #prepend-inner>
          <v-btn
            class="upload-button"
            icon
            :disabled="store.loading"
            @click="openFileUpload"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <template #append>
          <v-btn
            icon
            class="submit-button"
            color="primary"
            :disabled="store.loading || !store.messageInput.length"
            @click="chat"
          >
            <v-icon>mdi-arrow-up</v-icon>
          </v-btn>
        </template>
      </d-input-textarea>

      <input
        ref="fileInput"
        type="file"
        multiple
        style="display: none"
        @change="handleFileUpload"
      />
    </v-col>
  </v-row>
  <v-row no-gutters>
    <v-col cols="12" class="mt-2">
      <playground-assistant :scope="assistantScope" />
      <v-chip
        v-for="uploadedFile in store.uploadedFiles"
        :key="uploadedFile.filename"
        class="ma-1"
        small
        close
        @click:close="store.removeUploadedFile(uploadedFile.filename)"
      >
        <v-icon left x-small>mdi-file-outline</v-icon>
        {{
          uploadedFile.filename?.length > 35
            ? uploadedFile.filename.slice(0, 35) + '…'
            : uploadedFile.filename
        }}
      </v-chip>
    </v-col>
  </v-row>
</v-container>
