import Vue from 'vue'
import merge from 'lodash/merge'

import { actionTypeSetMutations, actionStateTypes } from '@/types/calendar'

/**
 * Generates dynamic setter mutation for given actionName
 *
 * Not passing an argument flushes the action state
 *
 * @param {String} actionName - the key of action state
 */
const actionsMutations = (actionName) => ({
  [actionTypeSetMutations[actionName]]: (
    state,
    // when pasting the cuting event of recuring event,
    // excepted to get he original single event
    { active = false, event = { id: null }, originalEvent = null } = {}
  ) => {
    Vue.set(state.actionStates, actionName, { active, event, originalEvent })
  },
})

/**
 * Generate all the mutations from actionStateTypes
 */
const generateActionStateMutations = () => {
  const actionStateMutations = actionStateTypes.map((actionName) =>
    actionsMutations(actionName)
  )
  return actionStateMutations.reduce((a, b) => merge(a, b))
}

const actionStateMutations = generateActionStateMutations()
export default actionStateMutations
