<template>
  <fragment>
    <v-chip
      class="ma-1"
      outlined
      small
      @click="showAllAssistants = !showAllAssistants"
    >
      {{
        showAllAssistants
          ? 'Hide assistants'
          : selectedAssistant
          ? `Assistant: ${selectedAssistant.title}`
          : 'Change assistants'
      }}
      <v-icon v-if="!showAllAssistants" right>mdi-chevron-down</v-icon>
      <v-icon v-else right>mdi-chevron-up</v-icon>
    </v-chip>
    <span v-if="showAllAssistants">
      <v-chip
        v-for="assistant in assistants"
        :key="assistant.id"
        :color="selectedAssistant?.id === assistant.id ? 'primary' : ''"
        class="ma-1"
        small
        @click="handleAssistantChange(assistant)"
      >
        {{ assistant.title }}
      </v-chip>
      <v-chip
        class="ma-1"
        small
        outlined
        @click="store.skipToolCalls = !store.skipToolCalls"
      >
        {{ store.skipToolCalls ? 'Make' : 'Skip' }} tool calls
        <v-icon right small>mdi-tools</v-icon>
      </v-chip>
    </span>
  </fragment>
</template>

<script lang="ts">
import { ref, computed } from '@vue/composition-api';
import { usePlaygroundStore } from '@/modules/playground/playground-store';
const __sfc_main = {};
__sfc_main.props = {
  scope: {
    type: String,
    default: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const store = usePlaygroundStore();
  const showAllAssistants = ref(false);
  const selectedAssistant = computed(() => store.currentAssistant);
  const assistants = computed(() => props.scope ? store.assistants.filter((assistant: {
    name: string | null;
  }) => assistant.name?.startsWith(props.scope)) : store.assistants);
  const handleAssistantChange = (assistant: NonNullable<typeof assistants.value>[number]) => {
    store.newConversation({
      assistantName: assistant.name,
      context: store.context
    });
    showAllAssistants.value = false;
  };
  return {
    store,
    showAllAssistants,
    selectedAssistant,
    assistants,
    handleAssistantChange
  };
};
export default __sfc_main;
</script>
