import { inject } from '@vue/composition-api'
import { useTranslation } from '@/i18n/i18n'

import type Snackbar from '@/types/snackbar'
import type { ApolloQueryResult } from '@apollo/client'
import type { Maybe, SimpleError } from '@generated/graphql'

type MutationPayload = {
  [key: string]: {
    errors?: Maybe<SimpleError[]>
  }
}
type MutationAction = ApolloQueryResult<MutationPayload>
type DialogAction = boolean
type SnackbarAction = MutationAction | DialogAction

/**
 * Composable for handling snackbar notifications in the application.
 *
 * @remarks
 * Provides a wrapper for mutation and dialog operations to automatically show success/error snackbars.
 * For mutations:
 * - Shows success messages based on operation type (Save/Delete)
 * - Shows error messages if mutation returns errors in the payload
 * - Falls back to generic error for other failures
 * For dialogs:
 * - Ignores true/false results (no snackbar shown)
 * - Shows generic error for failures
 *
 * @example
 * ```ts
 * const { withSnackbar, showSnackbar } = useSnackbar()
 *
 * // Wrap a mutation - will show success/error based on response
 * await withSnackbar(mutate({
 *   variables: { input: { name: 'Test' } }
 * }))
 *
 * // Mutation with error handling
 * await withSnackbar(mutate({...})) // Shows error if response contains errors
 *
 * // Wrap a dialog
 * await withSnackbar(dialog.show(component))
 *
 * // Show a custom snackbar
 * showSnackbar({ text: 'Custom message' })
 * ```
 *
 * @returns Object containing:
 * - showSnackbar: Direct access to show snackbar
 * - withSnackbar: Wrapper function for automatic snackbars given a promise
 */
export const useSnackbar = () => {
  const snackbar: Snackbar = inject('$snackbar')
  const { t, i18next } = useTranslation()

  function getTranslationKey(mutationName: string, type: 'success' | 'error') {
    // Try to find a translation key for the operation...
    //
    // The lookup follows this convention:
    // de:
    //   snackbars:
    //     {operationName}:
    //       success: ...
    //       error: ...
    //
    // Example: snackbars:llmTestMessagesSave.success
    //
    if (i18next.exists(`snackbars:${mutationName}.${type}`)) {
      return `snackbars:${mutationName}.${type}`
    }

    // ... otherwise we have a heuristic to find the fallback key.
    if (
      mutationName.endsWith('Save') ||
      mutationName.endsWith('Create') ||
      mutationName.endsWith('Update')
    ) {
      return `snackbars:${type}.saved`
    } else if (mutationName.endsWith('Delete')) {
      return `snackbars:${type}.deleted`
    } else {
      return `snackbars:${type}.misc`
    }
  }

  const withSnackbar = (result: Promise<SnackbarAction>) =>
    result.then(infoSnackbar).catch(errorSnackbar)

  const infoSnackbar = (result: SnackbarAction) => {
    // TODO: This handles whatever dialog emit and return in the promise.
    // But we should probably standardize the dialog behavior
    if (result === false || result === true || !result) return

    let message = 'snackbars:success.misc'

    if (result?.data) {
      const mutationName = Object.keys(result.data)[0]

      if (result.data[mutationName].errors?.length) {
        message = getTranslationKey(mutationName, 'error')
      } else {
        message = getTranslationKey(mutationName, 'success')
      }
    }

    snackbar.show({
      text: t(message),
    })
  }

  const errorSnackbar = (error: SnackbarAction) => {
    if (!error) return
    if (error instanceof KeyboardEvent) return

    snackbar.show({
      text: t('snackbars:error.misc'),
    })
  }

  return {
    showSnackbar: snackbar.show,
    withSnackbar,
  }
}
