<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div>
    <d-data-table-loader
      :enable-loading="enableLoading"
      :loading="loading"
      :loading-message="loadingMessage"
    />
    <v-data-table
      v-show="!loading"
      :headers="tableHeaders"
      :items="tableItems"
      :search="search"
      :item-class="rowWithEvent"
      :items-per-page="itemsPerPage"
      :footer-props="mergedFooterProps"
      v-bind="{ ...defaultAttrs, ...$attrs }"
      :class="[tableClass, 'd-data-table', { 'fixed-width': fixedWidth }]"
      :hide-default-footer="pageInfo ? true : 'hide-default-footer' in $attrs"
      v-on="$listeners"
      @click:row="handleRowClick"
    >
      <template #header.select>
        <v-simple-checkbox
          v-if="selectable"
          color="primary"
          :indeterminate="someItemsSelected"
          :value="allItemsSelected"
          @input="toggleAllSelectableItems"
        />
      </template>
      <!-- Handle table with <template #item="{ item }"> slot (events-table.vue, findings-table.vue) -->
      <template v-if="$scopedSlots['item']" #item="itemProps">
        <slot name="item" v-bind="itemProps">-</slot>
      </template>

      <template v-if="$scopedSlots['no-data']" #no-data>
        <slot name="no-data">{{ $t('common:empty_table') }}</slot>
      </template>

      <template v-if="!$scopedSlots['item']" #body>
        <tbody v-if="tableItems.length === 0">
          <tr class="v-data-table__empty-wrapper">
            <td :colspan="tableHeaders.length" style="text-align: center">
              <slot name="no-data">{{
                search
                  ? $t('common:no_search_result')
                  : $t('common:empty_table')
              }}</slot>
            </td>
          </tr>
        </tbody>
        <draggable
          v-model="tableItems"
          tag="tbody"
          :class="{ 'draggable-wrapper': areItemsDraggable }"
          ghost-class="ghost-row"
          chosen-class="is-row-dragged"
          :disabled="!areItemsDraggable || isFilterApplied"
          :clone="onClone"
          :animation="sortAnimationDuration"
          @start="dragging = true"
          @end="onEnd"
        >
          <d-data-table-row
            v-for="(item, index) in tableItems"
            :key="index"
            :item="item"
            :headers="tableHeaders"
            :row-click="rowClickHandler ? handleRowClick : null"
            :dragging="dragging"
          >
            <template #item.select>
              <v-simple-checkbox
                v-if="itemSelectable(item)"
                color="primary"
                :value="isSelected(item)"
                @input="itemSelect(item, $event)"
              />
              <v-simple-checkbox
                v-else-if="
                  !itemSelectable(item) && !tooltipForNonSelectable(item)
                "
                color="primary"
                :disabled="true"
                off-icon="mdi-alert-box-outline"
                :value="isSelected(item)"
                @input="itemSelect(item, $event)"
              />
              <d-tooltip
                v-else-if="
                  !itemSelectable(item) && tooltipForNonSelectable(item)
                "
                bottom
              >
                <template #activator="{ on }">
                  <v-simple-checkbox
                    color="primary"
                    :disabled="true"
                    off-icon="mdi-alert-box-outline"
                    :value="isSelected(item)"
                    v-on="on"
                    @input="itemSelect(item, $event)"
                  />
                </template>
                <span>{{ tooltipForNonSelectable(item) }}</span>
              </d-tooltip>
            </template>
            <template #item.drag>
              <v-icon :color="isFilterApplied ? '#ccc' : ''" small>
                mdi-drag-horizontal-variant
              </v-icon>
            </template>
            <template v-for="(_, name) in $scopedSlots" #[name]="itemProps">
              <template v-if="name === 'item.actions'">
                <d-data-table-item-actions :key="name">
                  <slot :name="name" v-bind="itemProps" />
                </d-data-table-item-actions>
              </template>
              <template v-else-if="name === 'item.visible-actions'">
                <div :key="name" class="d-flex">
                  <slot :name="name" v-bind="itemProps" />
                </div>
              </template>
              <slot v-else :name="name" v-bind="itemProps">–</slot>
            </template>
          </d-data-table-row>
        </draggable>
      </template>

      <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}–{{ pageStop }} {{ $t('common:pagination_from') }}
        {{ itemsLength }}
      </template>
      <template #footer="{ props }">
        <div
          v-if="pageInfo"
          class="footer--border-top d-flex align-center justify-end px-6 py-2 print-hidden"
        >
          <span class="text-caption text-no-wrap">{{
            props.itemsPerPageText
          }}</span>
          <v-menu v-if="itemsPerPageOptions" offset-y>
            <template #activator="{ on }">
              <v-btn depressed class="ml-3 mr-7" small v-on="on" @click.stop>
                {{ itemsPerPage }}
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-card list>
              <v-list-item
                v-for="option in itemsPerPageOptions"
                :key="option.text || option"
                @click="
                  $emit('paginate', {
                    action: 'refresh',
                    pageInfo,
                    itemsPerPage: option.text ? option.value : option,
                  })
                "
              >
                <v-list-item-title>
                  {{ option.text ? $t(option.text) : option }}
                </v-list-item-title>
                <slot></slot>
              </v-list-item>
            </v-card>
          </v-menu>
          <span class="text-caption mr-7 text-no-wrap"
            >Total: {{ itemsTotal }}</span
          >
          <v-btn
            type="button"
            icon
            :disabled="!pageInfo?.hasPreviousPage"
            @click="
              $emit('paginate', {
                action: 'start',
                pageInfo,
                itemsPerPage,
              })
            "
          >
            <v-icon>mdi-page-first</v-icon>
          </v-btn>
          <v-btn
            type="button"
            class="mr-2"
            icon
            :disabled="!pageInfo?.hasPreviousPage"
            @click="
              $emit('paginate', {
                action: 'prev',
                pageInfo,
                itemsPerPage,
              })
            "
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            type="button"
            class="ml-2"
            icon
            :disabled="!pageInfo?.hasNextPage"
            @click="
              $emit('paginate', {
                action: 'next',
                pageInfo,
                itemsPerPage,
              })
            "
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn
            type="button"
            icon
            :disabled="!pageInfo?.hasNextPage"
            @click="
              $emit('paginate', {
                action: 'end',
                pageInfo,
                itemsPerPage,
              })
            "
          >
            <v-icon>mdi-page-last</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import DDataTableRow from './d-data-table-row.vue'
import DDataTableLoader from './d-data-table-loader.vue'
import DDataTableItemActions from './d-data-table-item-actions.vue'
import clone from 'lodash/clone'
import { defaultItemsPerPageOptions } from '@/libs/use-pagination'

export default {
  name: 'DDataTable',
  components: {
    Draggable,
    DDataTableRow,
    DDataTableLoader,
    DDataTableItemActions,
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    selectable: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectableRule: {
      type: Function,
      required: false,
      default: () => {
        return { value: true, tooltip: '' }
      },
    },
    sortable: {
      type: Boolean,
      required: false,
    },
    enableLoading: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: false,
    },
    search: {
      type: String,
      default: null,
    },
    rowClickHandler: {
      type: Function,
      default: null,
    },
    footerProps: {
      type: Object,
      default: () => ({}),
    },
    areItemsDraggable: {
      type: Boolean,
      default: false,
    },
    isFilterApplied: {
      type: Boolean,
      default: false,
    },
    tableClass: {
      type: String,
      default: '',
    },
    fixedWidth: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: 'loading',
    },
    pageInfo: {
      type: Object,
      default: null,
      required: false,
    },
    itemsPerPage: {
      type: Number,
      default: 5,
      required: false,
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => defaultItemsPerPageOptions(),
      required: false,
    },
    itemsTotal: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  data() {
    return {
      tableItems: [],
      defaultAttrs: {},
      defaultFooterProps: {
        itemsPerPageOptions: [
          20,
          50,
          100,
          { value: -1, text: this.$t('common:all') },
        ],
        showFirstLastPage: true,
        itemsPerPageText: this.$t('common:rows_per_page'),
      },
      sortAnimationDuration: 100,
      dragging: false,
      selectedItems: [],
    }
  },
  computed: {
    selectableItems() {
      return this.items.filter((item) => this.selectableRule(item)?.value)
    },
    allItemsSelected() {
      // if no selectable items are present, nothing is selected
      if (
        this.selectableRule &&
        this.selectable &&
        !this.selectableItems.length
      ) {
        return false
      }
      const isAllItemsSelected =
        this.selectableItems.length === this.selectedItems.length
      this.$emit('is-all-items-selected', isAllItemsSelected)
      return isAllItemsSelected
    },
    someItemsSelected() {
      return !this.allItemsSelected && this.selectedItems.length > 0
    },
    mergedFooterProps() {
      return {
        ...this.defaultFooterProps,
        ...this.footerProps,
      }
    },
  },
  watch: {
    items: {
      immediate: true,
      handler(currentItems, _oldItems) {
        this.tableItems = clone(currentItems)
      },
    },
    headers: {
      immediate: true,
      deep: true,
      handler() {
        this.prepareHeaders()
      },
    },
    selectedItems(currentValue, _oldValue) {
      this.$emit('select', currentValue)
    },
    selectableItems(value) {
      this.$emit('selectable-items', value)
    },
  },
  created() {
    // Apply namespaces of the component where the component is used
    if (this._i18nOptions && !this._i18nOptions.namespaces.includes('common'))
      this._i18nOptions.namespaces.push('common')
  },
  methods: {
    toggleAllSelectableItems() {
      this.allItemsSelected ? this.selectNone() : this.selectAll()
    },
    tooltipForNonSelectable(item) {
      return this.selectableRule(item).tooltip
    },
    selectAll() {
      this.selectedItems = [...this.selectableItems]
    },
    selectNone() {
      this.selectedItems = []
    },
    addSelected(newItem) {
      if (!this.isSelected(newItem)) {
        this.selectedItems.push(newItem)
      }
    },
    isSelected(item) {
      return !!this.selectedItems.find(
        (selectedItem) => selectedItem.id === item.id
      )
    },
    removeSelected(removeItem) {
      this.selectedItems = this.selectedItems.filter(
        (item) => item.id !== removeItem.id
      )
    },
    itemSelectable(item) {
      if (!this.selectable) return false

      return this.selectableItems.find(
        (selectableItem) => selectableItem === item
      )
    },
    itemSelect(item, value) {
      value ? this.addSelected(item) : this.removeSelected(item)
    },
    prepareHeaders() {
      this.tableHeaders = this.headers.map((header) => {
        // Set a default column's width for the actions and visible actions
        if (
          (header.value === 'actions' || header.value === 'visible-actions') &&
          !header.width
        ) {
          header.width = '24px'
        }
        if (header) header.text = header.text ? this.$t(header.text) : null
        return header
      })

      // Add a drag column if the items are draggable
      if (this.areItemsDraggable) {
        this.tableHeaders.unshift({
          value: 'drag',
          sortable: false,
          width: '24px',
        })
      }
      if (this.selectable) {
        this.tableHeaders.unshift({
          value: 'select',
          sortable: false,
          width: '24px',
          class: 'select',
        })
      }
    },
    handleRowClick(item) {
      if (!this.rowClickHandler) return
      this.rowClickHandler(item)
    },
    rowWithEvent() {
      return this.rowClickHandler ? 'row-with-event' : ''
    },
    onClone(item) {
      return clone(item)
    },
    onEnd() {
      this.dragging = false
      this.$emit('sort', this.tableItems)
    },
  },
}
</script>

<style lang="scss" scoped>
.d-data-table::v-deep {
  &.fixed-width table {
    table-layout: fixed;
  }

  .ui-layout--patient & {
    table {
      margin: 0;
    }
  }

  .footer--border-top {
    border-top: 1px solid var(--v-grey-10-base);
  }

  .v-data-table__wrapper > table > thead > tr > th:first-child {
    padding-left: 24px !important;
    max-width: 48px !important;
  }

  .v-data-table__wrapper > table > thead > tr > th:last-child {
    padding-right: 24px !important;
  }

  .v-data-table__wrapper > table > thead > tr > th {
    // Set similar headers to d-nested-tables
    padding: 3px 8px !important;
    height: 48px;
  }

  .select {
    width: 1px !important;
    text-align: center !important;
    max-width: 24px;
  }

  tbody tr {
    cursor: default;
    td:first-child {
      padding-left: 24px !important;
    }

    td:last-child {
      padding-right: 24px !important;
    }

    td {
      padding: 8px !important;
    }

    &:hover {
      background-color: transparent !important;
    }

    &.row-with-event:hover {
      cursor: pointer;
      background-color: var(--v-grey-05-base) !important;
    }

    &.ghost-row {
      opacity: 1;
      background-color: var(--green-light) !important;

      td {
        color: transparent;

        ::v-deep * {
          visibility: hidden;
        }
      }
    }

    &.draggable-wrapper {
      display: contents;
    }

    &.is-row-dragged * {
      cursor: grabbing !important;
    }
  }

  tbody tr td.hand:hover {
    cursor: grab;
  }

  &.v-data-table--dense table > tbody > tr > td {
    font-size: 13px;
  }

  .table-input {
    background-color: #f2f2f2;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    height: 22px;
    padding: 6px 9px;
    width: 60px;

    &:focus {
      outline: none;
      border: 1px solid #e1e1e1 !important;
    }
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  /* Footer */
  .v-data-footer__select .v-input__slot {
    padding: 0 0 0 8px;
    background-color: var(--c-gray);
    border-radius: 4px;

    &:before {
      border: none;
    }
  }
}
</style>
