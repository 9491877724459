
<d-section
  :subtitle="assistantTitle"
  class="d-flex flex-column"
  :flat="props.embedded"
  mb="mb-0"
>
  <template v-if="!embedded" #title>
    <h5 class="text-h5">
      <span v-if="!editingTitle" @click="editingTitle = true">{{
        store.currentConversation.title
      }}</span>
      <d-input-text
        v-if="editingTitle"
        solo
        hide-details
        :value="store.currentConversation.title"
        @change="updateTitle($event)"
      >
      </d-input-text>
    </h5>
  </template>

  <v-container ref="chatContainer" class="auto-scroll" @scroll="handleScroll">
    <playground-conversation />
  </v-container>

  <v-fade-transition>
    <v-btn
      v-show="showScrollButton"
      fab
      small
      fixed
      color="primary"
      class="scroll-btn"
      @click="handleScrollClick"
    >
      <v-icon>mdi-chevron-down</v-icon>
    </v-btn>
  </v-fade-transition>

  <v-footer color="transparent" padless>
    <playground-input :assistant-scope="assistantScope" />
  </v-footer>
</d-section>
