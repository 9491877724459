import { get, post, put, destroy } from './axiosWrapper'

const routes = {
  patient: (patientId) => `${routes.patients}/${patientId}`,
  patient_services: (patientId) => `${routes.patient(patientId)}/services`,
  calendar_resources: '/json/calendar_resources',
  message_templates: '/json/message_templates',
  message_template: (id) => `${routes.message_templates}/${id}`,
  calendar_resources_availability: '/json/calendar_resources_availability',
  event_types: '/json/event_types',
  events: '/json/events',
  event: (id) => `${routes.events}/${id}`,
  send_event_appointment: (id) => `${routes.event(id)}/send_appointment`,
  notificatin_template: (eventId) =>
    `${routes.event(eventId)}/notification_templates`,
  patients: '/json/patients',
  physicians: '/json/physicians',
  team_members: '/json/team_members',
  dated_positions: (patientId) => `/patients/${patientId}/dated_positions`,
}

const services = {
  index: (patientId, config = {}) =>
    get(`${routes.patient_services(patientId)}`, config),
  create: (patientId, position, config = {}) =>
    post(`${routes.patient_services(patientId)}`, { position }, config),
  update: (patientId, { ids, attrs }, config = {}) =>
    post(`${routes.patient_services(patientId)}/bulk_update`, {
      ...config,
      ids,
      attrs,
    }),
  destroy: (patientId, ids, config = {}) =>
    destroy(`${routes.patient_services(patientId)}/bulk_destroy`, {
      ...config,
      params: { ids },
    }),
}

const calendar_resources = {
  index: (config = {}) => get(routes.calendar_resources, config),
  show: (id, config = {}) => get(`${routes.calendar_resources}/${id}`, config),
}

const message_templates = {
  update: (id, message_template, config = {}) =>
    put(routes.message_template(id), { message_template }, config),
}

const calendar_resources_availability = {
  show: (id, config = {}) =>
    get(`${routes.calendar_resources_availability}/${id}`, config),
}

const event_types = {
  index: (config = {}) => get(routes.event_types, config),
  show: (id, config = {}) => get(`${routes.event_types}/${id}`, config),
}

const eventPermittedParams = {
  event: [
    'start',
    'end',
    'text',
    'patientId',
    'physicianId',
    'calendarRoomId',
    'calendarResourceIds',
    'eventTypeId',
    'allDay',
  ],
}

const events = {
  permittedParams: eventPermittedParams,
  index: ({ from = null, to = null, params = {}, config = {} } = {}) =>
    get(`${routes.events}`, {
      params: { from, to, ...params, ...config.params },
      ...config,
    }),
  show: (id, { historic = false }, config = {}) =>
    get(routes.event(id), { params: { historic } }, config),
  create: (event, config = {}) => post(routes.events, { event }, config),
  update: (id, event, config = {}) => put(routes.event(id), { event }, config),
  destroy: (id, config = {}) => destroy(routes.event(id), config),
  send_appointment: (id, payload, config = {}) =>
    post(routes.send_event_appointment(id), payload, config),
}

const patients = {
  index: (config = {}) => get(routes.patients, config),
  show: (id, config = {}) => get(`${routes.patients}/${id}`, config),
}

const physicians = {
  index: (config = {}) => get(routes.physicians, config),
  show: (id, config = {}) => get(`${routes.physicians}/${id}`, config),
}

const team_members = {
  index: (config = {}) => get(routes.team_members, config),
  show: (id, config = {}) => get(`${routes.team_members}/${id}`, config),
}

const dated_positions = {
  index: ({ patientId }, config = {}) =>
    get(`${routes.dated_positions(patientId)}.json`, config),
  preselect: ({ patientId, mark, mappedIds }, config = {}) =>
    post(
      `${routes.dated_positions(
        patientId
      )}/preselect?mark=${mark}&position_ids=${mappedIds}`,
      config
    ),
  bulkDestroy: ({ patientId, ids }, config = {}) =>
    destroy(`${routes.dated_positions(patientId)}/bulk_destroy`, {
      ...config,
      params: { ids },
    }),
}

const notification_templates = {
  show: (eventId, config = {}) =>
    get(routes.notificatin_template(eventId), config),
}

export default {
  services,
  calendar_resources,
  message_templates,
  calendar_resources_availability,
  dated_positions,
  event_types,
  events,
  patients,
  physicians,
  team_members,
  notification_templates,
}
