/**
 * This mixin adds the capability to group table rows by a desired property provided by `sectionKey` prop.
 * It relies on an `sortedItems` array which is used in a computed property to generate section rows.
 * The collection of both sections and regular items is accessible through the `sectionsAndItems` array.
 * Note that the setter in that computed prop does set the `sortedItems` array on which the mixin relies.
 */
import groupBy from 'lodash/groupBy'
import get from 'lodash/get'
import { Sortable } from 'sortablejs'

export default {
  props: {
    /**
     * By specifiying a section key, the items can be grouped into sections.
     * The sections must be provided through the sectionsProp array.
     */
    sectionKey: {
      type: String,
      default: null,
    },
    idOfItemsWithoutSection: {
      type: Number | String,
      default: undefined,
    },
    /**
     * An array of unique sections.
     * Each section should have at least a unique id and a name property.
     * The id can be a string or number.
     */
    sectionsProp: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      /**
       * array needed to avoid flickering state when dragg-/dropping rows
       */
      sortedSections: [],
    }
  },
  watch: {
    sectionsProp: {
      immediate: true,
      handler(currentSections) {
        if (!currentSections) return
        this.sortedSections = [...currentSections]
      },
    },
  },
  computed: {
    sectionsAndItems: {
      get() {
        if (!this.sectionKey || (!this.sectionsProp && this.items.length === 0))
          return this.sortedItems

        return [
          // items not belonging to a section
          ...(this.itemsGroupedBySectionKey[this.idOfItemsWithoutSection] ||
            []),
          // sections and their respective items
          ...this.sortedSections
            .filter((section) => section.id)
            .flatMap((section) => [
              { ...section, isSection: true }, // build section row
              ...(this.itemsGroupedBySectionKey[section.id] || []),
            ]),
        ]
      },
      set(value) {
        if (!this.sectionKey) {
          this.sortedItems = value // Note that we're setting sortedItems here
          this.updateSort()
        } else {
          const { regularItems, sections } = this.updateSortWithSections(value)
          this.sortedItems = regularItems // Note that we're setting sortedItems here
          this.sortedSections = sections
        }
      },
    },
    selectableSections() {
      return this.sections.filter((section) => {
        const sectionContainsSelectableItem = this.selectableItems.some(
          (item) => get(item, this.sectionKey) === section.id
        )
        return sectionContainsSelectableItem
      })
    },
    itemsGroupedBySectionKey() {
      return groupBy(this.sortedItems, this.sectionKey)
    },
    selectedItemsGroupedBySectionKey() {
      return groupBy(this.selectedItems, this.sectionKey)
    },
    sections() {
      return this.sectionsAndItems.filter((item) => item.isSection)
    },
  },
  methods: {
    isSectionSelected(section) {
      const isSectionSelected =
        this.selectedItemsGroupedBySectionKey[section.id]?.length ===
        this.itemsGroupedBySectionKey[section.id]?.length
      const element = document.getElementById(section.id)
      if (element) {
        isSectionSelected
          ? Sortable.utils.select(element.parentElement)
          : Sortable.utils.deselect(element.parentElement)
      }
      return isSectionSelected
    },
    sectionSelectable(section) {
      return this.selectableSections.find(
        (selectableSection) => selectableSection.id === section.id
      )
    },
    sectionSelect(section, value) {
      this.itemsGroupedBySectionKey[section.id].forEach((item) =>
        value ? this.addSelected(item) : this.removeSelected(item)
      )
    },
    isSectionIndeterminate(section) {
      return (
        this.selectedItemsGroupedBySectionKey[section.id]?.length > 0 &&
        this.selectedItemsGroupedBySectionKey[section.id]?.length <
          this.itemsGroupedBySectionKey[section.id]?.length
      )
    },
    updateSort() {
      this.$emit('sort', this.sortedItems)
    },
    updateSortWithSections(sectionsAndItems) {
      let currentSectionId = this.idOfItemsWithoutSection

      const sectionsAndItemsClone = [...sectionsAndItems]

      sectionsAndItemsClone.forEach((item) => {
        if (item?.isSection) {
          currentSectionId = item.id
        } else {
          // we need to sort on client before the server responds to avoid flickering
          this.$set(item, this.sectionKey, currentSectionId)
        }
      })

      const regularItems = sectionsAndItemsClone.filter(
        (item) => !item.isSection
      )

      const sections = this.sectionsProp
        ? sectionsAndItemsClone.filter((item) => item.isSection)
        : []

      this.$emit('sort', sectionsAndItemsClone)

      return { regularItems, sections }
    },
  },
}
