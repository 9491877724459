import { defineStore, acceptHMRUpdate } from 'pinia'
import { gql } from '@apollo/client/core'
import {
  provideApolloClient,
  useMutation,
  useQuery,
} from '@vue/apollo-composable'
import { apolloClient } from '@/plugins/apollo-client'
import consumer from '../../channels/consumer'

const ASSISTANTS = gql`
  query Assistants {
    llmAssistants {
      nodes {
        id
        name
        title
        taskSuggestions {
          title
          prompt
        }
      }
    }
  }
`

const CONVERSATIONS = gql`
  query Conversations {
    llmConversations(sortColumn: "created_at", sortDirection: DESC) {
      nodes {
        id
        title
        assistant {
          id
          name
          title
          taskSuggestions {
            title
            prompt
          }
        }
        messages {
          id
          role
          content
          attachments {
            id
            filename
            contentType
            url
          }
          toolCalls {
            id
            name
            content
            data
            arguments
            state
            preview {
              ... on Todo {
                dueOn
                comment
                isCompleted
                responsibleTeamMember {
                  id
                  initials
                }
                location {
                  id
                  name
                }
                patient {
                  id
                  displayName
                }
              }
              ... on Letter {
                title
                text
              }
              ... on ClinicalRecord {
                text
                teeth
                at
              }
            }
          }
        }
      }
    }
  }
`

const CONVERSATION_DELETE = gql`
  mutation ConversationDelete($id: ID!) {
    llmConversationDelete(id: $id) {
      id
    }
  }
`

const CONVERSATION_SAVE = gql`
  mutation ConversationSave($id: ID!, $title: String!) {
    llmConversationSave(id: $id, title: $title) {
      llmConversation {
        id
        title
      }
    }
  }
`

const MESSAGE_DELETE = gql`
  mutation MessageDelete($id: ID!) {
    llmMessageDelete(id: $id) {
      id
    }
  }
`

const PLAYGROUND_CHAT = gql`
  mutation PlaygroundChat(
    $conversationId: ID
    $assistantName: ID
    $request: String!
    $context: String
    $files: [String!]
    $skipToolCalls: Boolean
  ) {
    playgroundChat(
      conversationId: $conversationId
      assistantName: $assistantName
      request: $request
      context: $context
      files: $files
      skipToolCalls: $skipToolCalls
    ) {
      conversation {
        id
        title
        assistant {
          id
          name
          title
          taskSuggestions {
            title
            prompt
          }
        }
        messages {
          id
          role
          content
          attachments {
            id
            filename
            contentType
            url
          }
          toolCalls {
            id
            name
            content
            data
            arguments
            state
            preview {
              ... on Todo {
                dueOn
                comment
                isCompleted
                responsibleTeamMember {
                  id
                  initials
                }
                location {
                  id
                  name
                }
                patient {
                  id
                  displayName
                }
              }
              ... on Letter {
                title
                text
              }
              ... on ClinicalRecord {
                text
                teeth
                at
              }
            }
          }
        }
      }
    }
  }
`

const LLM_CONVERSATION_REGENERATE = gql`
  mutation LlmConversationRegenerate(
    $conversationId: ID!
    $skipToolCalls: Boolean
  ) {
    llmConversationRegenerate(
      conversationId: $conversationId
      skipToolCalls: $skipToolCalls
    ) {
      conversation {
        id
        title
        assistant {
          id
          name
          title
          taskSuggestions {
            title
            prompt
          }
        }
        messages {
          id
          role
          content
          attachments {
            id
            filename
            contentType
            url
          }
          toolCalls {
            id
            name
            content
            data
            arguments
            state
            preview {
              ... on Todo {
                dueOn
                comment
                isCompleted
                responsibleTeamMember {
                  id
                  initials
                }
                location {
                  id
                  name
                }
                patient {
                  id
                  displayName
                }
              }
              ... on Letter {
                title
                text
              }
              ... on ClinicalRecord {
                text
                teeth
                at
              }
            }
          }
        }
      }
      errors {
        message
      }
    }
  }
`

const LLM_MESSAGE_SAVE = gql`
  mutation saveLlmMessage($id: ID, $conversationId: ID!, $content: String!) {
    llmMessageSave(
      id: $id
      conversationId: $conversationId
      content: $content
    ) {
      message {
        id
        content
        role
      }
      errors {
        message
      }
    }
  }
`

const LLM_TOOL_CALL_CONFIRM = gql`
  mutation confirmLlmToolCall($id: ID!, $arguments: JSON) {
    llmToolCallConfirm(id: $id, arguments: $arguments) {
      id
      name
      content
      data
      arguments
      state
    }
  }
`

const LLM_TOOL_CALL_UPDATE = gql`
  mutation updateLlmToolCall($id: ID!, $arguments: JSON!) {
    llmToolCallUpdate(llmToolCallId: $id, arguments: $arguments) {
      llmToolCall {
        id
        name
        content
        data
        state
        arguments
        preview {
          ... on Todo {
            dueOn
            comment
            isCompleted
          }
          ... on Letter {
            title
            text
          }
          ... on ClinicalRecord {
            text
            teeth
            at
          }
        }
      }
    }
  }
`

function subscribeToChatResponse(
  conversationId,
  callback,
  connected = null,
  disconnected = null
) {
  let messageQueue = []
  let expectedSequence = 1

  return consumer.subscriptions.create(
    {
      channel: 'LlmChatResponseChannel',
      conversation_id: conversationId,
    },
    {
      received(data) {
        messageQueue.push(data)
        messageQueue.sort((a, b) => a.sequence - b.sequence)

        while (
          messageQueue.length &&
          messageQueue[0].sequence === expectedSequence
        ) {
          const message = messageQueue.shift()
          callback(message)
          expectedSequence++
        }
      },
      connected() {
        if (connected) {
          console.debug('Connected to chat response channel')
          connected()
        }
      },
      disconnected() {
        if (disconnected) {
          disconnected()
          console.debug('Disconnected from chat response channel')
        }
      },
    }
  )
}

function unsubscribeFromChatResponse(subscription) {
  consumer.subscriptions.remove(subscription)
}

function buildFreshConversation() {
  return {
    id: null,
    title: 'New Chat',
    messages: [],
  }
}

export const usePlaygroundStore = defineStore('playground', {
  state: () => ({
    conversationId: null,
    assistantName: null,
    context: {},
    freshConversation: buildFreshConversation(),
    userMessage: null,
    streamingMessage: null,
    loading: false,
    messageInput: '',
    uploadedFiles: [],
    assetsVisible: false,
    currentAsset: {
      title: null,
      content: null,
    },
    currentToolCallStatus: null, // Filled in by the tool calls
    currentProgressStep: 0,
    skipToolCalls: false,
    editingMessages: [],
    // Apollo queries
    assistantsQuery: null,
    conversationsQuery: null,
  }),
  getters: {
    assistants() {
      if (!this.assistantsQuery) {
        this.assistantsQuery = provideApolloClient(apolloClient)(() =>
          useQuery(ASSISTANTS)
        )
        return []
      }

      return this.assistantsQuery.result?.llmAssistants?.nodes
    },

    conversations() {
      if (!this.conversationsQuery) {
        this.conversationsQuery = provideApolloClient(apolloClient)(() =>
          useQuery(CONVERSATIONS)
        )
        return []
      }

      const conversationNodes =
        this.conversationsQuery.result?.llmConversations?.nodes

      if (this.conversationId === null) {
        return [this.freshConversation, ...conversationNodes]
      } else {
        return conversationNodes
      }
    },

    isFreshConversation() {
      return (
        this.conversationId === null &&
        this.assistantName === null &&
        this.messageInput === '' &&
        this.userMessage === null
      )
    },

    currentConversation() {
      if (!this.conversationId) {
        return this.freshConversation
      }

      return (
        this.conversations?.find((item) => item.id === this.conversationId) || {
          messages: [],
        }
      )
    },

    currentMessages() {
      const messages = this.currentConversation?.messages || []

      if (this.userMessage) {
        return [...messages, this.userMessage]
      } else {
        return messages
      }
    },

    currentAssistant() {
      if (this.currentConversation?.assistant) {
        return this.currentConversation.assistant
      }

      return this.assistants?.find((item) => item.name === this.assistantName)
    },

    toolCallPreviews() {
      const assistantMessages = this.currentMessages.filter(
        (message) => message.role === 'assistant'
      )

      const lastAssistantMessageWithPreview = [...assistantMessages]
        .reverse()
        .find((message) =>
          (message.toolCalls || []).some(
            (toolCall) => toolCall.preview && toolCall.state === 'pending'
          )
        )

      if (!lastAssistantMessageWithPreview) return []

      return (lastAssistantMessageWithPreview.toolCalls || []).filter(
        (toolCall) => toolCall.preview && toolCall.state === 'pending'
      )
    },
  },
  actions: {
    showAssets() {
      this.assetsVisible = true
    },

    hideAssets() {
      this.assetsVisible = false
    },

    selectAsset(asset) {
      this.currentAsset = asset
      this.showAssets()
    },

    newConversation({ assistantName = null, context = {} }) {
      this.conversationId = null
      this.freshConversation = buildFreshConversation()
      this.assistantName = assistantName
      this.context = context
      this.messageInput = ''
      this.uploadedFiles = []
      this.conversationsQuery = null
      this.hideAssets()
    },

    async uploadFile(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.addEventListener('load', () => {
        this.uploadedFiles = [
          ...this.uploadedFiles,
          {
            filename: file.name,
            content: reader.result,
          },
        ]
      })
    },

    removeUploadedFile(filename) {
      const index = this.uploadedFiles.findIndex(
        (file) => file.filename === filename
      )

      if (index !== -1) {
        this.uploadedFiles.splice(index, 1)
      }
    },

    receiveMessageStream(data) {
      if (data.tool_call) {
        const existingToolCall = this.streamingMessage.toolCalls?.find(
          (tc) => tc.id === data.tool_call.id
        )
        if (!existingToolCall) {
          this.streamingMessage.toolCalls.push(data.tool_call)
        }
        this.currentToolCallStatus = data.tool_call.status
      } else if (data.token) {
        this.streamingMessage.content += data.token
      } else if (data.finish_reason) {
        return data.finish_reason
      }
    },

    async newMessage(request) {
      const { mutate, loading } = provideApolloClient(apolloClient)(() =>
        useMutation(PLAYGROUND_CHAT)
      )

      this.loading = loading
      this.currentProgressStep = 0

      const files = this.uploadedFiles.map(() => {
        return {
          filename: 'Upload',
        }
      })

      this.userMessage = {
        role: 'user',
        content: request,
        files,
      }

      this.messageInput = ''

      this.streamingMessage = {
        role: 'assistant',
        content: '',
        toolCalls: [],
      }

      let { promise, resolve } = Promise.withResolvers()

      const subscription = subscribeToChatResponse(
        this.conversationId || 'new',
        async (data) => {
          this.receiveMessageStream(data)
          // NOTE: we may need to handle other finish reasons
          // https://platform.openai.com/docs/guides/function-calling#edge-cases
          if (data.finish_reason === 'stop') {
            console.debug(
              'Finished streaming in message, unsubscribing from chat response channel'
            )
            unsubscribeFromChatResponse(subscription)

            this.currentToolCallStatus = null
            this.currentProgressStep = 0

            await this.conversationsQuery?.refetch()

            this.loading = false
            this.streamingMessage = null
            this.uploadedFiles = []
            this.userMessage = null

            resolve(true)
          }
        },
        async () => {
          try {
            const { data } = await mutate({
              conversationId: this.conversationId,
              assistantName: this.assistantName,
              context: JSON.stringify(this.context),
              request,
              files: this.uploadedFiles.map((file) => file.content),
              skipToolCalls: this.skipToolCalls,
            })

            if (data.playgroundChat.conversation) {
              this.conversationId = data.playgroundChat.conversation.id
            }
          } catch (error) {
            console.error('Error calling playgroundChat mutation:', error)
          } finally {
            this.loading = false
          }
        }
      )

      return promise
    },

    editMessage(message) {
      this.cancelEditingMessage(message.id)

      const copy = window.structuredClone(message)
      this.editingMessages.push(copy)
    },

    getEditedMessage(messageId) {
      return (
        this.editingMessages.find((message) => message.id === messageId) || null
      )
    },

    async commitEditedMessage(id) {
      const message = this.getEditedMessage(id)
      this.cancelEditingMessage(id)

      const { mutate } = provideApolloClient(apolloClient)(() =>
        useMutation(LLM_MESSAGE_SAVE)
      )

      await mutate({
        id: message.id,
        conversationId: this.conversationId,
        content: message.content,
      })
    },

    updateEditedMessage(id, content) {
      const message = this.getEditedMessage(id)
      message.content = content
    },

    isEditingMessage(messageId) {
      return !!this.getEditedMessage(messageId)
    },

    cancelEditingMessage(messageId) {
      this.editingMessages = this.editingMessages.filter(
        (message) => message.id !== messageId
      )
    },

    async regenerateLastMessage() {
      if (!this.conversationId) {
        console.error('No active conversation to regenerate')
        return
      }

      const { mutate, loading } = provideApolloClient(apolloClient)(() =>
        useMutation(LLM_CONVERSATION_REGENERATE)
      )

      this.loading = loading

      this.streamingMessage = {
        role: 'assistant',
        content: '',
        toolCalls: [],
      }

      const subscription = subscribeToChatResponse(
        this.conversationId,
        (data) => {
          this.receiveMessageStream(data)
          if (data.finish_reason) {
            console.debug(
              'Finished streaming in message, unsubscribing from chat response channel'
            )
            unsubscribeFromChatResponse(subscription)
            this.loading = false
            this.streamingMessage = null
            this.conversationsQuery?.refetch()
          }
        },
        async () => {
          try {
            const { data } = await mutate({
              conversationId: this.conversationId,
              skipToolCalls: this.skipToolCalls,
            })

            if (data.llmConversationRegenerate.errors) {
              console.error(
                'Error regenerating message:',
                data.llmConversationRegenerate.errors
              )
              // Handle the error appropriately (e.g., show a notification to the user)
            } else {
              // Update the store with the regenerated conversation
              this.conversationsQuery?.refetch()
            }
          } catch (error) {
            console.error(
              'Error calling regenerateLastMessage mutation:',
              error
            )
            // Handle the error appropriately (e.g., show a notification to the user)
          } finally {
            this.loading = false
          }
        }
      )
    },

    deleteMessage(id) {
      provideApolloClient(apolloClient)(() =>
        useMutation(MESSAGE_DELETE)
      ).mutate({ id }, { refetchQueries: ['Conversations'] })
    },

    deleteConversation(id) {
      // Select the next message if the current conversation is deleted
      if (this.conversationId === id) {
        const index = this.conversations.findIndex(
          (conversation) => conversation.id === id
        )
        if (this.conversations.length > index) {
          this.conversationId = this.conversations[index + 1].id
        }
      }

      provideApolloClient(apolloClient)(() =>
        useMutation(CONVERSATION_DELETE)
      ).mutate({ id }, { refetchQueries: ['Conversations'] })
    },

    confirmToolCall(id, data) {
      const { mutate } = provideApolloClient(apolloClient)(() =>
        useMutation(LLM_TOOL_CALL_CONFIRM)
      )

      const payload = data ? { arguments: data } : {}
      return mutate({ id, ...payload })
    },

    updateConversationTitle(title) {
      provideApolloClient(apolloClient)(() =>
        useMutation(CONVERSATION_SAVE)
      ).mutate({ id: this.conversationId, title })
    },

    updateToolCall(id, args) {
      provideApolloClient(apolloClient)(() =>
        useMutation(LLM_TOOL_CALL_UPDATE)
      ).mutate({ id, arguments: args })
    },

    copy(messageContent) {
      navigator.clipboard.writeText(messageContent)
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePlaygroundStore, import.meta.hot))
}
