
<v-container>
  <v-row>
    <v-col v-for="(assistant, idx) in store.assistants" :key="idx" cols="4">
      <v-card
        @click="store.newConversation({ assistantName: assistant.name })"
      >
        <v-card-title>{{ assistant.title }}</v-card-title>
      </v-card>
    </v-col>
  </v-row>
</v-container>
