
<fragment>
  <v-chip
    class="ma-1"
    outlined
    small
    @click="showAllAssistants = !showAllAssistants"
  >
    {{
      showAllAssistants
        ? 'Hide assistants'
        : selectedAssistant
        ? `Assistant: ${selectedAssistant.title}`
        : 'Change assistants'
    }}
    <v-icon v-if="!showAllAssistants" right>mdi-chevron-down</v-icon>
    <v-icon v-else right>mdi-chevron-up</v-icon>
  </v-chip>
  <span v-if="showAllAssistants">
    <v-chip
      v-for="assistant in assistants"
      :key="assistant.id"
      :color="selectedAssistant?.id === assistant.id ? 'primary' : ''"
      class="ma-1"
      small
      @click="handleAssistantChange(assistant)"
    >
      {{ assistant.title }}
    </v-chip>
    <v-chip
      class="ma-1"
      small
      outlined
      @click="store.skipToolCalls = !store.skipToolCalls"
    >
      {{ store.skipToolCalls ? 'Make' : 'Skip' }} tool calls
      <v-icon right small>mdi-tools</v-icon>
    </v-chip>
  </span>
</fragment>
