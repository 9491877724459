import RestEntity from './rest-entity'

class TeamMember extends RestEntity {
  entityName = 'teamMember'

  roles = {
    PHYSICIAN: 'physician',
    DHPA: 'dhpa',
    DA: 'da',
  }

  sort(team_member_ids) {
    return this.post('sort', { team_member_ids: team_member_ids })
  }

  invite(teamMemberId) {
    return this.get(`${teamMemberId}/invite`)
  }

  lastEventCreated(teamMemberId) {
    return this.get(`${teamMemberId}/events/last`)
  }

  lastEventTypeId(teamMemberId) {
    if (teamMemberId) {
      return this.lastEventCreated(teamMemberId)
        .then(({ event }) => event.eventTypeId)
        .catch(() => null)
    }
  }

  isPractitioner(user) {
    return (
      this.hasRole(user, this.roles.PHYSICIAN) ||
      this.hasRole(user, this.roles.DHPA)
    )
  }

  isAssistant(user) {
    return this.hasRole(user, this.roles.DA)
  }

  hasRole(user, role) {
    return user.role === role
  }
}

export default new TeamMember()
