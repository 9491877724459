<template>
  <d-form :form-data.sync="formData">
    <d-modal>
      <d-modal-title title="Voice Input Debug Options" />
      <d-modal-content>
        <v-row>
          <v-col cols="12" md="6">
            <d-input-select
              v-model="formData.voice_model"
              :items="voice_models"
              label="Voice Model"
              hide-details
            />
          </v-col>
          <v-col cols="12" md="6">
            <d-input-select
              v-model="formData.correction_model"
              :items="correction_models"
              label="Correction Model"
              hide-details
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <d-input-textarea
              v-model="formData.prompt"
              label="Prompt"
              hide-details
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <d-input-select
              v-model="formData.selected_audio"
              :items="test_files"
              label="Test Files"
              hide-details
            />
            <v-btn icon @click="transcribeSelected">
              <v-icon>mdi-play</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8">
            <audio
              v-if="voiceInputStore.audioSrc"
              class="audio-player"
              :src="voiceInputStore.audioSrc"
              controls
            ></audio>
          </v-col>
        </v-row>
      </d-modal-content>

      <d-modal-actions>
        <d-btn role="secondary" label="common:cancel" @click="emit('cancel')" />
        <d-btn label="common:save" @click="save" />
      </d-modal-actions>
    </d-modal>
  </d-form>
</template>

<script lang="ts">
import { ref, onMounted, watch } from '@vue/composition-api';
import { useVoiceInputStore } from '../voice-input-store';
import { useSnackbar } from '@/libs/use-snackbar';
import gql from 'graphql-tag';
import { apolloClient } from '@/plugins/apollo-client';
import { provideApolloClient } from '@vue/apollo-composable';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const VOICE_MODELS_QUERY = gql`
  query VoiceTranscriptionModels {
    voiceTranscriptionModels {
      voiceModels
      correctionModels
      testFiles
    }
  }
`;
  const VOICE_TEST_AUDIO_QUERY = gql`
  query VoiceTestAudio($filename: String!) {
    voiceTestAudio(filename: $filename)
  }
`;
  const emit = __ctx.emit;
  const voiceInputStore = useVoiceInputStore();
  const {
    showSnackbar
  } = useSnackbar();
  const formData = ref({
    voice_model: '',
    correction_model: '',
    selected_audio: '',
    prompt: ''
  });
  const voice_models = ref<{
    text: string;
    value: string;
  }[]>([]);
  const correction_models = ref<string[]>([]);
  const test_files = ref<string[]>([]);

  // Initialize models
  onMounted(async () => {
    try {
      const {
        data
      } = await provideApolloClient(apolloClient)(() => apolloClient.query({
        query: VOICE_MODELS_QUERY
      }));

      // Add test models
      voice_models.value = [{
        text: 'Test - Short Response',
        value: 'test_short'
      }, {
        text: 'Test - Long Response',
        value: 'test_long'
      }, {
        text: 'Test - Error Case',
        value: 'test_error'
      }, ...(data.voiceTranscriptionModels.voiceModels || []).map((model: string) => ({
        text: model,
        value: model
      }))];
      correction_models.value = data.voiceTranscriptionModels.correctionModels;
      test_files.value = data.voiceTranscriptionModels.testFiles;

      // Set initial values from store
      formData.value.voice_model = voiceInputStore.$state.voice_model;
      formData.value.correction_model = voiceInputStore.$state.correction_model;
      formData.value.prompt = voiceInputStore.$state.prompt;
    } catch (error) {
      console.error('Failed to fetch models:', error);
      // Add test models even if fetch fails
      voice_models.value = [{
        text: 'Test - Short Response',
        value: 'test_short'
      }, {
        text: 'Test - Long Response',
        value: 'test_long'
      }, {
        text: 'Test - Error Case',
        value: 'test_error'
      }];
    }
  });

  // Watch for changes in selected audio
  watch(() => formData.value.selected_audio, async newValue => {
    if (newValue) {
      try {
        const {
          data
        } = await provideApolloClient(apolloClient)(() => apolloClient.query({
          query: VOICE_TEST_AUDIO_QUERY,
          variables: {
            filename: newValue
          }
        }));
        voiceInputStore.audioSrc = data.voiceTestAudio;
      } catch (error) {
        console.error('Failed to fetch audio:', error);
      }
    }
  });
  const transcribeSelected = async () => {
    try {
      const response = await fetch(voiceInputStore.audioSrc);
      const audioBlob = await response.blob();
      const correction = await voiceInputStore.transcribeAudio(audioBlob);
      emit('close', correction);
    } catch (error) {
      console.error('Transcription failed:', error);
      if (error && typeof error === 'object' && 'message' in error) {
        showSnackbar({
          text: (error.message as string)
        });
      }
    }
  };
  const save = () => {
    voiceInputStore.setModelParameters({
      voice_model: formData.value.voice_model,
      correction_model: formData.value.correction_model,
      prompt: formData.value.prompt
    });
    emit('close');
  };
  return {
    emit,
    voiceInputStore,
    formData,
    voice_models,
    correction_models,
    test_files,
    transcribeSelected,
    save
  };
};
export default __sfc_main;
</script>

<style scoped>
.audio-player {
  width: 100%;
}
</style>
