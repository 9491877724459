<template>
  <div>
    <v-autocomplete
      v-if="!patientCreation"
      v-model="model"
      v-bind="defaultInputBindings"
      :items="items"
      :loading="loading"
      :search-input.sync="search"
      :prepend-inner-icon="prependInnerIcon"
      :no-data-text="noDataTextTranslated"
      :cache-items="false"
      :class="cssClassName"
      item-text="fullnameWithNo"
      item-value="id"
      no-filter
      v-on="defaultHandlers"
      @input="$emit('input', $event)"
    >
      <template #item="{ item, attrs }">
        <div v-bind="attrs">
          <span>{{ item.fullnameWithNo }}, </span>
          <span class="grey--text ml-1"
            >*{{ item.yearOfBirth }}
            {{
              showLocationIfDifferent && item.defaultLocation.id != locationId
                ? `(${item.defaultLocation.shortname})`
                : ''
            }}</span
          >
        </div>
      </template>
      <template
        v-if="
          enableNewPatient &&
          !$featureFlag.feature_flags_enable_external_patient_data
        "
        #append-item
      >
        <div
          class="px-4 v-list-item v-list-item--link theme--light"
          @click="setPatientCreation(true)"
        >
          <span>
            {{ $t('calendar:eventForm.createNewPatient') }}
          </span>
        </div>
      </template>
    </v-autocomplete>
    <div v-if="patientCreation" class="pb-8">
      <d-input-text
        v-model="newPatientData.prename"
        label="activerecord:attributes.person.prename"
      />
      <d-input-text
        v-model="newPatientData.name"
        label="activerecord:attributes.person.name"
      />
      <d-input-text
        v-model="newPatientData.phone"
        label="activerecord:attributes.person.phone"
      />
      <d-input-text
        v-model="newPatientData.mobile"
        label="activerecord:attributes.person.mobile"
        :hide-details="true"
      />
      <v-row align="center">
        <v-col cols="12" class="pt-5">
          <div class="text-right">
            <d-btn label="cancel" role="secondary" @click="cancelNewPatient" />
            <d-btn
              label="patient_save"
              align-right
              class="ml-2"
              :loading="loading"
              @click="createNewPatient"
            />
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import patientService from '../../../services/patient'
import dInputAutocompleteMixin from './d-input-autocomplete-mixin'
import dInputComponentMixin from './d-input-component-mixin'
/**
 * dInputPatient
 * @class
 * @mixes dInputAutocompleteMixin
 * @mixes dInputComponentMixin
 *
 * @prop {String} prependInnerIcon prepends an icon inside the component's input, uses the same syntax as `v-icon`- default is 'person' *optional*
 * @prop {String} label the i18next translation key for the label - default is 'models.patient' *optional*
 *
 * ```vue
 * <d-input-patient
 *   v-model="event.patientId"
 *   clearable
 *   filled
 * />
 * ```
 *
 * ```vue
 * <d-form :form-data="{ id: '1', patientId: '222' }">
 *  <d-input-patient name="patientId"
 * </d-form>
 * ```
 */
const dInputPatient = {
  mixins: [dInputComponentMixin, dInputAutocompleteMixin],
  props: {
    prependInnerIcon: { type: String, default: 'mdi-account' },
    label: { type: String, default: 'models.patient' },
    enableNewPatient: { type: Boolean, default: false },
    showLocationIfDifferent: { type: Boolean, default: false },
  },
  data() {
    return {
      patientCreation: false,
      newPatientData: {
        id: null,
        prename: '',
        name: '',
        phone: '',
        mobile: '',
        defaultLocationId: '',
      },
      loading: false,
    }
  },
  computed: {
    ...mapGetters({ locationId: 'common/getCurrentLocationId' }),
    cssClassName() {
      // if multiple instances are rendered in the same view there needs to be a unique class to attach to
      return `d-input-patient-${this._uid}`
    },
  },
  created() {
    this.newPatientData.defaultLocationId = this.locationId
    this.$emit('patientCreationActive', this.patientCreation)
  },
  methods: {
    async searchCallback(term) {
      this.newPatientData.prename = term
      const { patients } = await patientService.search(
        term,
        null,
        this.locationId
      )
      if (this.model) {
        // Fetch selected patient to make sure it appears at least once on the list
        try {
          const { patient } = await patientService.fetch(this.model)
          patients.unshift(patient)
        } catch (err) {
          // TODO: Ignore 404, but fail otherwise
        }
      }
      return patients
    },
    async createNewPatient() {
      this.loading = true
      const { patient } = await patientService.save(this.newPatientData)
      this.loading = false
      this.model = patient.id
      this.search = patient.fullnameWithNo
      this.patientCreation = false
      this.resetNewPatientData()
    },
    async cancelNewPatient() {
      this.patientCreation = false
      this.resetNewPatientData()
    },
    resetNewPatientData() {
      this.newPatientData = {
        id: null,
        prename: '',
        name: '',
        phone: '',
        mobile: '',
        defaultLocationId: this.locationId,
      }
      this.$emit('setPatientCreation', this.patientCreation)
    },
    setPatientCreation(newState) {
      this.patientCreation = newState
      this.$emit('setPatientCreation', this.patientCreation)
    },
  },
}
export default dInputPatient
</script>
