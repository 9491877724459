import RestEntity from './rest-entity'
import { get } from '@/libs/axiosWrapper'
import { createAbortController } from './abort-controller'

class Patient extends RestEntity {
  entityName = 'patient'
  searchAbortController = createAbortController()

  search(
    term = '',
    include_archive = null,
    locationId = null,
    isAbortable = true
  ) {
    // If abortable, cancel the previous request
    if (isAbortable) {
      this.searchAbortController.abort() // Cancel previous request
      this.searchAbortController.reset() // Create a new AbortController for the current request
    }

    this.searchAbortController.setAbortable(isAbortable)

    return this.get('/search', {
      params: { term, include_archive, locationId },
      signal: this.searchAbortController.getSignal(),
    })
  }

  profileImageUploaderUrl(id) {
    return get(this.entityPath(id) + '/profile_image_uploader_url')
  }

  mediaAssetUploaderUrl(id) {
    return get(this.entityPath(id) + '/media_asset_uploader_url')
  }
}

export default new Patient()
