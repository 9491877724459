
<div>
  <div v-if="store.isFreshConversation">
    <playground-task-selection />
  </div>
  <div v-else>
    <v-container fluid class="px-0 py-0">
      <playground-message
        v-for="(message, idx) in store.currentMessages"
        :key="idx"
        :message="message"
      />

      <playground-message
        v-if="store.streamingMessage"
        :message="store.streamingMessage"
        :tool-call-status="currentStatus"
        :show-tool-calls="true"
      />
    </v-container>
  </div>
</div>
