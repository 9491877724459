<template>
  <v-autocomplete
    ref="autocomplete"
    v-model="model"
    v-bind="defaultInputBindings"
    :class="cssClassName"
    :loading="isLoading"
    :items="filterItems"
    :prepend-inner-icon="prependInnerIcon"
    :no-data-text="noDataTextTranslated"
    :search-input.sync="query"
    auto-select-first
    item-text="label"
    item-value="polyId"
    no-filter
    :background-color="backgroundColor"
    v-on="defaultHandlers"
    @keydown.native.enter="handleEnterKey"
  >
    <template #item="{ item }">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <v-list-item-title v-html="highlight(item.label)" />
    </template>
  </v-autocomplete>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import escapeRegExp from 'lodash/escapeRegExp'
import businessObjectService from '../../../services/business-object'
import { businessObjectTypes } from '../../../services/position'
import dInputComponentMixin from './d-input-component-mixin'
import dInputAutocompleteMixin from './d-input-autocomplete-mixin'
import { DInputBusinessObjectFilter } from './d-input-business-object-filter'

export const dInputBusinessObjectFilter = async () => {
  const dInputBusinessObjectFilter = new DInputBusinessObjectFilter(
    await businessObjectService.get()
  )
  return dInputBusinessObjectFilter
}

const dInputBusinessObject = {
  mixins: [dInputComponentMixin, dInputAutocompleteMixin],
  data() {
    return {
      isLoading: true,
      items: this.initialItems,
      query: '',
      dInputBusinessObjectFilter: null,
    }
  },
  props: {
    tariffType: {
      type: String,
      required: true,
      validator: (value) => Object.values(businessObjectTypes).includes(value),
    },
    parentBusinessObjectId: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: 'attributes.position.business_object',
    },
    enterKeyDisabled: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: null,
    },
  },
  computed: {
    filterItems() {
      if (!this.dInputBusinessObjectFilter) return this.initialItems || []

      return this.dInputBusinessObjectFilter.result(
        this.tariffType,
        this.parentBusinessObjectId,
        this.query,
        this.model
      )
    },
    cssClassName() {
      // if multiple instances are rendered in the same view there needs to be a unique class to attach to
      return `d-input-business-object-${this._uid}`
    },
  },
  watch: {
    model: {
      handler() {
        this.newBusinessObject()
      },
      immediate: true,
    },
  },
  async mounted() {
    this.dInputBusinessObjectFilter = await dInputBusinessObjectFilter()

    this.isLoading = false

    if (this.parentBusinessObjectId) {
      this.$refs.autocomplete.isMenuActive = true
    }
  },
  methods: {
    handleEnterKey() {
      if (
        this.model &&
        this.filterItems.length === 1 &&
        !this.enterKeyDisabled
      ) {
        this.dForm.submit()
      }
    },
    highlighter(label, matcher) {
      return label.replace(
        matcher,
        (match) => `<span class="highlight">${match}</span>`
      )
    },
    highlight(label) {
      if (isEmpty(this.query)) return label

      if (/^\d+$/.test(this.query)) {
        if (label.includes(this.query)) {
          return this.highlighter(label, this.query)
        }

        const dottedQuery = [this.query.slice(0, 1), this.query.slice(1)].join(
          '.'
        )

        return this.highlighter(label, dottedQuery)
      }

      const matcher = new RegExp(escapeRegExp(this.query), 'i')
      return this.highlighter(label, matcher)
    },
    newBusinessObject() {
      if (!this.dInputBusinessObjectFilter) {
        this.$emit('object-selected', null)
        return
      }

      const item = this.dInputBusinessObjectFilter.items.find(
        (item) => item.polyId === this.model
      )

      this.$emit('object-selected', item)
    },
  },
}
export default dInputBusinessObject
</script>

<style lang="scss" scoped>
.highlight {
  background-color: rgba(133, 50, 174, 0.16);
}
</style>
