import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from '@apollo/client/core'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { onError } from '@apollo/client/link/error'
import { logErrorMessages } from '@vue/apollo-util'
import introspectionQueryResultData from '@generated/fragmentTypes.json'

const URI = '/denteo/graphql'

const defaultOptions = {
  query: {
    fetchPolicy: 'no-cache',
  },
}

// Cache implementation
const cache = new InMemoryCache({
  possibleTypes: introspectionQueryResultData.possibleTypes,
})

// Handle errors
const errorLink = onError((error) => {
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV !== 'production') {
    logErrorMessages(error)
  }
})

// Batched HTTP link
// Be aware that one single query in the batch can slow down the whole batch
// therefore for slow queries, use normal HTTP link via useBatch: false
const batchHttpLink = new BatchHttpLink({
  uri: URI,
  batchMax: 5,
  batchInterval: 10,
})

// Normal HTTP link
const normalHttpLink = createHttpLink({
  uri: URI,
})

// Create the apollo client with configurable link selection
// To disable batch mode, modify useQuery(QUERY, { context: { useBatch: false } })
const selectLink = new ApolloLink((operation, forward) => {
  const { useBatch = true } = operation.getContext()
  return (useBatch ? batchHttpLink : normalHttpLink).request(operation, forward)
})

export const apolloClient = new ApolloClient({
  link: errorLink.concat(selectLink),
  cache,
  defaultOptions,
})
